import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


function ImageTrip() {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><img src="../../assets/image//private-jet.png" alt="" /></SwiperSlide>
      <SwiperSlide><img src="../../assets/image//private-jet.png" alt="" /></SwiperSlide>
      <SwiperSlide><img src="../../assets/image//private-jet.png" alt="" /></SwiperSlide>
      <SwiperSlide><img src="../../assets/image//private-jet.png" alt="" /></SwiperSlide>
      
    </Swiper>
  )
}

export default ImageTrip
