import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../API/authapi';

function MainFooter() {
  const [homeData, setHomeData] = useState({});

  useEffect(() => {
    axiosInstance.get('/api/home')
      .then(response => {
        setHomeData( response.data[0]);
        // Fetch trips after setting homeData
            }) 
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  
  return (
    <div>
      <footer className=' bg-white p-10 flex  flex-col gap-20 md:flex-row'>
        <div className=' md:w-1/2 text-left flex flex-col gap-4'>
          <a href='/' className='logo text-3xl text-theblue-400 cursor-default'>TheTrip <br />Curaçao</a>
          <p>is your trusted travel partner for unforgettable Curaçao adventures. We specialize in personalized travel experiences, offering exclusive tours, luxury accommodations, and expert local insights. Discover the magic of Curaçao with us and create memories that last a lifetime.</p>
          <p> 
            Chamber of commerce number: {homeData.chamber_of_commerce_number} <br />
            Legal name: {homeData.legal_name} <br />
            Email address: {homeData.email_address} <br />
            Contact: {homeData.contact}
          </p>
    
        </div>
        <div className=' flex justify-between md:w-1/2'>
          <div className=' md:w-1/2 flex flex-col gap-4'>
            <p>Help & Support</p>
            <a href="/contact" className=' font-normal underline text-gray-700'>Contact Us</a>
            <a href="/termsconditions" className=' font-normal underline text-gray-700'>Terms & Conditions</a>
            <a href="/contact" className=' font-normal underline text-gray-700'>Cancellation policy</a>
            <a href="/cookies" className=' font-normal underline text-gray-700'>Cookies</a>
            <a href="/privacy" className=' font-normal underline text-gray-700'>Privacy</a>
            <a href="/Disclaimer" className=' font-normal underline text-gray-700'>Disclaimer</a>
          </div>
          <div className=' md:w-1/2 flex flex-col gap-4'>
            <p>Pages</p>
            <a href="/" className=' font-normal underline text-gray-700'>Home</a>
            <a href="/about" className=' font-normal underline text-gray-700'>About</a>
            <a href="/local-experience" className=' font-normal underline text-gray-700'>Local experience</a>
            <a href="/contact" className=' font-normal underline text-gray-700'>Special request</a>
            <a href="/activities" className=' font-normal underline text-gray-700'>Activity</a>
          </div></div>
      </footer>
    </div>
  )
}

export default MainFooter
