import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, message, notification } from 'antd';
import { axiosInstance } from '../../API/authapi'; // Adjust the import according to your file structure
import { useNavigate } from 'react-router-dom';

function CompanyDirectory() {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [password, setPassword] = useState('');
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const correctPassword = 'admin123'; // Replace with the actual password


  useEffect(() => {
    axiosInstance.get('/api/company/get')
      .then(response => {
        if (response.status === 101) {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        } else {
          setUsers(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        } else {
          notification.error({
            message: 'Error',
            description: error.response ? error.response.data.message : error.message,
          });
        }
      });
  }, []);

  const showPasswordModal = (user) => {
    setCurrentUser(user);
    setIsModalVisible(true);
  };

  const handlePasswordOk = () => {
    if (password === correctPassword) {
      setIsPasswordModalVisible(false);
      setIsModalVisible(true);
    } else {
      message.error('Incorrect password');
    }
  };

  const handlePasswordCancel = () => {
    setIsPasswordModalVisible(false);
  };

  const handleOk = () => {
    axiosInstance.put(`/api/company/update/${currentUser._id}`, {
      username: currentUser.username,
      email: currentUser.email,
      phone: currentUser.phone,
      address: currentUser.address,
      bank: currentUser.bank,
      commission: currentUser.commission,
      title: currentUser.title
    })
    .then(response => {
      const updatedUsers = users.map(user => user.id === currentUser.id ? response.data : user);
      setUsers(updatedUsers);
      setIsModalVisible(false);
      notification.success({ message: 'User updated successfully!' });
    })
    .catch(error => {
      console.error('Error updating user:', error);
      notification.error({
        message: 'Failed to update user',
        description: error.response ? error.response.data.message : error.message,
      });
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddOk = () => {
    axiosInstance.post('/api/company/add', {
      username: currentUser.username,
      email: currentUser.email,
      phone: currentUser.phone,
      address: currentUser.address,
      bank: currentUser.bank,
      commission: currentUser.commission,
      title: currentUser.title
    })
    .then(response => {
      setUsers([...users, response.data]);
      setIsAddModalVisible(false);
      notification.success({ message: 'User added successfully!' });
    })
    .catch(error => {
      console.error('Error creating user:', error);
      notification.error({
        message: 'Failed to add user',
        description: error.response ? error.response.data.message : error.message,
      });
    });
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const handleRemoveUser = () => {
    axiosInstance.delete(`/api/company/delete/${currentUser._id}`)
      .then(() => {
        setUsers(users.filter(user => user.id !== currentUser.id));
        setIsModalVisible(false);
        message.success('User removed successfully');
      })
      .catch(error => {
        console.error('Error removing user:', error);
        notification.error({
          message: 'Failed to remove user',
          description: error.response ? error.response.data.message : error.message,
        });
      });
  };

  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10 flex justify-between'>
        <h1 className='text-gray-800 font-medium text-xl'>Company Directory</h1>
        <Button type="primary" className="bg-blue-600 text-white" onClick={() => { setIsAddModalVisible(true); setCurrentUser({}); }}>
          Add User
        </Button>
      </header>

      <section className='mt-10 grid grid-cols-3 gap-3'>
        {users.map(user => (
          <div key={user.id} className="bg-white p-6 rounded-bl-2xl rounded-br-2xl rounded-t-[80px] shadow-md max-w-[350px] mb-4">
            <div className="flex items-center gap-5">
              <div className="w-24 h-24 rounded-full bg-gray-300 mb-4"></div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">{user.username}</h2>
                <p className="text-gray-500">{user.title}</p>
              </div>
            </div>
            <hr className="my-4" />
            <div className="text-left space-y-2">
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Mail Id:</span>
                <span className="text-gray-600">{user.email}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Phone:</span>
                <span className="text-gray-600">{user.phone}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Address:</span>
                <span className="text-gray-600">{user.address}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Bank account:</span>
                <span className="text-gray-600">{user.bank}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Commission:</span>
                <span className="text-gray-600">{user.commission}</span>
              </div>
            </div>
            <div className="mt-6 text-right">
              <Button type="primary" className="bg-blue-600 text-white" onClick={() => showPasswordModal(user)}>Edit</Button>
            </div>
          </div>
        ))}
      </section>

      

      <Modal title="Edit User" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[
        <Button key="remove" type="danger" onClick={handleRemoveUser}>
          Remove User
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>
      ]}>
        <div className="space-y-4">
          <Input placeholder="Name" name="username" value={currentUser?.username || ''} onChange={handleInputChange} />
          <Input placeholder="Title" name="title" value={currentUser?.title || ''} onChange={handleInputChange} />
          <Input placeholder="Mail" name="email" value={currentUser?.email || ''} onChange={handleInputChange} />
          <Input placeholder="Phone" name="phone" value={currentUser?.phone || ''} onChange={handleInputChange} />
          <Input placeholder="Address" name="address" value={currentUser?.address || ''} onChange={handleInputChange} />
          <Input placeholder="Bank Account" name="bank" value={currentUser?.bank || ''} onChange={handleInputChange} />
          <Input placeholder="Commission" name="commission" value={currentUser?.commission || ''} onChange={handleInputChange} />
        </div>
      </Modal>

      <Modal title="Add User" visible={isAddModalVisible} onOk={handleAddOk} onCancel={handleAddCancel}>
        <div className="space-y-4">
          <Input placeholder="Name" name="username" value={currentUser?.username || ''} onChange={handleInputChange} />
          <Input placeholder="Title" name="title" value={currentUser?.title || ''} onChange={handleInputChange} />
          <Input placeholder="Mail" name="email" value={currentUser?.email || ''} onChange={handleInputChange} />
          <Input placeholder="Phone" name="phone" value={currentUser?.phone || ''} onChange={handleInputChange} />
          <Input placeholder="Address" name="address" value={currentUser?.address || ''} onChange={handleInputChange} />
          <Input placeholder="Bank Account" name="bank" value={currentUser?.bank || ''} onChange={handleInputChange} />
          <Input placeholder="Commission" name="commission" value={currentUser?.commission || ''} onChange={handleInputChange} />
        </div>
      </Modal>
    </main>
  );
}

export default CompanyDirectory;
