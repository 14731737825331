import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { axiosInstance } from '../API/authapi';

const Signup = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await axiosInstance.post('/api/user/register', values);
      message.success(response.data.message);
      navigate('/login');
    } catch (error) {
      message.error(error.response.data.message || 'Registration failed');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <a className='logo text-5xl text-theblue-400 mb-10'>TheTrip <br />Curaçao</a>
      <Card title="Sign Up" className="w-full max-w-md">
        <Form
          name="signup"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              { required: true, message: 'Please input your username!' },
              { pattern: /^\S+$/, message: 'Username cannot contain spaces' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Sign Up
            </Button>
          </Form.Item>
        </Form>

        <div className="text-center">
          <p>
            Already have an account?{' '}
            <a href="/login" className="text-theorange-400 cursor-pointer">
              Log In
            </a>
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Signup;
