import React, { useRef, useState, useEffect } from 'react';
import { Table, Modal, Button, Input, Space, message, Form } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

const AdminManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [users, setAdmins] = useState([]);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axiosInstance.get('/api/admin/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAdmins(response.data);
    } catch (error) {
      message.error('Failed to fetch users');
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate('/admin/login');
      } else {
        console.error('Error fetching trip data:', error);
      }
    }
  };

  const handleModalOpen = (user = null) => {
    setSelectedAdmin(user);
    if (user) {
      form.setFieldsValue(user);
      setIsEditing(true);
      setIsAdding(false);
    } else {
      form.resetFields();
      setIsAdding(true);
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
    setIsAdding(false);
  };

  const showRemoveConfirm = (userId) => {
    confirm({
      title: 'Are you sure you want to remove this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes, remove',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemoveAdmin(userId);
      },
    });
  };

  const handleRemoveAdmin = async (userId) => {
    try {
      await axiosInstance.delete(`/api/admin/delete/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAdmins(users.filter(user => user._id !== userId));
      message.success('Admin removed successfully');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to remove user');
    }
  };

  const handleSaveChanges = async () => {
    try {
      const values = await form.validateFields();
      await axiosInstance.put(`/api/admin/update/${selectedAdmin._id}`, values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const updatedAdmins = users.map(user =>
        user._id === selectedAdmin._id ? { ...user, ...values } : user
      );
      setAdmins(updatedAdmins);
      message.success('Admin details updated successfully');
      setIsModalVisible(false);
      setIsEditing(false);
    } catch (error) {
      message.error('Failed to update user details');
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleAddAdmin = async () => {
    try {
      const values = await form.validateFields();
      const response = await axiosInstance.post('/api/admin/create', values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      setAdmins([...users, response.data.newUser]);
      message.success('Admin added successfully');
      setIsModalVisible(false);
      setIsAdding(false);
    } catch (error) {
      message.error('Failed to add admin');
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 180 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Adminname',
      dataIndex: 'username',
      key: 'username',
      width: '30%',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleModalOpen(record)}>
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div className="user-management ml-52 pl-3 pr-3 pt-10">
      <div className="flex justify-between items-center">

        <h1 className="text-gray-800 font-medium text-xl">Admin Management</h1>
        <Button
          type="primary"
          onClick={() => handleModalOpen()}
        >
          Add Admin
        </Button>
      </div>

      <Table columns={columns} dataSource={users} rowKey="id" className="mt-10" />

      <Modal
        title={isAdding ? 'Add New Admin' : isEditing ? 'Edit Admin Details' : 'Admin Details'}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          isAdding && (
            <Button key="add" type="primary" onClick={handleAddAdmin}>
              Add Admin
            </Button>
          ),
          isEditing && (
            <Button key="save" type="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          ),
          isEditing && (
            <Button key="remove" type="danger" onClick={() => showRemoveConfirm(selectedAdmin._id)}>
              Remove Admin
            </Button>
          ),
          <Button key="back" onClick={handleModalCancel}>
            Close
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="username"
            label="Name"
            rules={[{ required: true, message: 'Please input the admin name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input the admin email!' }]}
          >
            <Input />
          </Form.Item>
          {isAdding && (
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input the admin password!' }]}
            >
              <Input.Password />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AdminManagement;