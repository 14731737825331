import React, { useState, useEffect } from 'react';
import { Card, Statistic, Row, Col, Table, DatePicker, Button, Modal } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;

const DashboardPage = () => {
  const [dashboardData, setDashboardData] = useState({
    totalBookings: 0,
    totalRevenue: 0,
    averagePrice: 0,
    upcomingBookings: 0,
    newBookings: [],
    mostBookedTrips: [],
    cancelledTrips: [],
    allBookings: []
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchDashboardData = async (fromDate, toDate) => {
    try {
      setLoading(true);
      let url = '/api/checkout/dashboard';
      if (fromDate && toDate) {
        url += `?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
      }
      const response = await axiosInstance.get(url);
      setDashboardData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate('/admin/login');
      } else {
        console.error('Error fetching dashboard data:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
    if (dates && dates[0] && dates[1]) {
      fetchDashboardData(dates[0], dates[1]);
    }
  };

  const options = { year: "numeric", month: "long", day: "numeric" };


  const bookingsColumns = [
    {
      title: 'Activity',
      dataIndex: 'trip',
      key: 'trip',
      render: (trip) => trip.tripname,
    },
    {
      title: 'Booking ID',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Price',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text) => `$${parseFloat(text).toFixed(2)}`,
    },
    {
      title: 'Client Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Client Contact',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    render: (text) => text ? new Date(text).toLocaleDateString(undefined, options) : 'Date Not Available',
    },
  ];

  const cancelledTripsColumns = [
    {
      title: 'Cancellation Validity',
      dataIndex: 'validity',
      key: 'validity',
      render: (validity) => (
        <span style={{ color: validity ? 'green' : 'red' }}>
          {validity ? 'Valid' : 'Invalid'}
        </span>
      ),
    },
    {
      title: 'Trip Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => text ? new Date(text).toLocaleDateString(undefined, options) : 'Date Not Available',
    },
    {
      title: 'Cancellation Requested Date ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleDateString(undefined, options),
    },
    {
      title: 'Hours',
      dataIndex: 'hoursDifference',
      key: 'hoursDifference',
      render: (hours) => {
        const totalSeconds = Math.round(hours * 3600); // Convert hours to total seconds and round off
        const hh = String(Math.floor(totalSeconds / 3600)).padStart(2, '0'); // Hours
        const mm = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0'); // Minutes
        const ss = String(totalSeconds % 60).padStart(2, '0'); // Seconds
    
        return `${hh}h:${mm}m:${ss}s`;
      },
    },
    
    
    {
      title: 'Client Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Booking Code',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Confirmation Status',
      dataIndex: 'validity',
      key: 'confirmStatus',
      render: (validity, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <button
            style={{
              backgroundColor: 'green',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => handleConfirm(record)}
          >
            Confirm
          </button>
          <button
            style={{
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => handleReject(record)}
          >
            Reject
          </button>
        </div>
      ),
    }
    
  ];

  
  const rescheduleTripsColumns = [
    {
      title: 'Reschedule Validity',
      dataIndex: 'validity',
      key: 'validity',
      render: (validity) => (
        <span style={{ color: validity ? 'green' : 'red' }}>
          {validity ? 'Valid' : 'Invalid'}
        </span>
      ),
    },
    {
      title: 'Trip Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => text ? new Date(text).toLocaleDateString(undefined, options) : 'Date Not Available',
    },
    {
      title: 'Trip Reschedule Date',
      dataIndex: 'reschedule_date',
      key: 'reschedule_date',
      render: (text) => text ? new Date(text).toLocaleDateString(undefined, options) : 'Date Not Available',
    },
    {
      title: 'Reschedule Requested Date ',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleDateString(undefined, options),
    },
    {
      title: 'Hours',
      dataIndex: 'hoursDifference',
      key: 'hoursDifference',
      render: (hours) => {
        const totalSeconds = Math.round(hours * 3600); // Convert hours to total seconds and round off
        const hh = String(Math.floor(totalSeconds / 3600)).padStart(2, '0'); // Hours
        const mm = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0'); // Minutes
        const ss = String(totalSeconds % 60).padStart(2, '0'); // Seconds
    
        return `${hh}h:${mm}m:${ss}s`;
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Booking Code',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Confirmation Status',
      dataIndex: 'validity',
      key: 'confirmStatus',
      render: (validity, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <button
            style={{
              backgroundColor: 'green',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => handleRescheduleConfirm(record)}
            disabled={validity}
          >
            Confirm
          </button>
          <button
            style={{
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => handleRescheduleReject(record)}
            disabled={validity}
          >
            Reject
          </button>
        </div>
      ),
    }    
  ];


  const handleRescheduleConfirm = async (record) => {
    Modal.confirm({
      title: 'Are you sure you want to confirm this reschedule request?',
      onOk: async () => {
        try {
          const updatedBooking = { tripStatus: 'confirmed' }; // Set status to 'Reschedule Confirmed'
          const response = await axiosInstance.put(`/api/checkout/bookings/${record._id}`, updatedBooking);

          if (response.status === 200) {
            console.log('Reschedule confirmed successfully.');
          }
        } catch (error) {
          console.error('Error confirming reschedule:', error);
        }
      },
      onCancel() {
        console.log('Reschedule confirmation cancelled');
      },
    });
  };

  const handleRescheduleReject = async (record) => {
    Modal.confirm({
      title: 'Are you sure you want to reject this reschedule request?',
      onOk: async () => {
        try {
          const updatedBooking = { tripStatus: 'Reschedule Rejected' }; // Set status to 'Reschedule Rejected'
          const response = await axiosInstance.put(`/api/checkout/bookings/${record._id}`, updatedBooking);

          if (response.status === 200) {
            console.log('Reschedule rejected successfully.');
          }
        } catch (error) {
          console.error('Error rejecting reschedule:', error);
        }
      },
      onCancel() {
        console.log('Reschedule rejection cancelled');
      },
    });
  };


  const handleConfirm = async (record) => {
    Modal.confirm({
      title: 'Are you sure you want to confirm this booking?',
      onOk: async () => {
        try {
          const updatedBooking = { tripStatus: 'Refunded' }; // Set booking status to 'cancelled'
          const response = await axiosInstance.put(`/api/checkout/bookings/${record._id}`, updatedBooking);

          if (response.status === 200) {
            console.log("ok");

          }
        } catch (error) {
          console.error('Error confirming booking:', error);
        }
      },
      onCancel() {
        console.log('Confirmation cancelled');
      },
    });
  };

  const handleReject = async (record) => {
    Modal.confirm({
      title: 'Are you sure you want to reject this booking?',
      onOk: async () => {
        try {
          const updatedBooking = { tripStatus: 'Refund Rejected' }; // Set booking status to 'rejected'
          const response = await axiosInstance.put(`/api/checkout/bookings/${record._id}`, updatedBooking);

          if (response.status === 200) {
            console.log("ok");

          }
        } catch (error) {
          console.error('Error rejecting booking:', error);
        }
      },
      onCancel() {
        console.log('Rejection cancelled');
      },
    });
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    navigate('/admin/login');
  };

  return (
    <div className="ml-52 pl-3 pr-3 pt-10">
      <div className='flex w-full justify-between mb-3'>
        <h1 className="text-gray-800 font-medium text-xl mb-5">Dashboard</h1>
        <div className="flex gap-3">
          <RangePicker onChange={handleDateRangeChange} value={dateRange} size='small' />
          <Button onClick={handleLogout} danger>Logout</Button>
        </div>
      </div>
      <Row gutter={16} className="mb-5">
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Bookings"
              value={dashboardData.totalBookings}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="trips"
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Revenue"
              value={dashboardData.totalRevenue}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="$"
              precision={2}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Average Price"
              value={dashboardData.averagePrice}
              valueStyle={{ color: '#3f8600' }}
              suffix="$"
              precision={2}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3 className='text-sm text-gray-500'>Most booked tours</h3>
            <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
              {dashboardData.mostBookedTrips.map((trip, index) => (
                <div key={index}>
                  <p className='flex'>
                    {trip.tripname} -
                    <p className='text-blue-800'>{trip.bookings} bookings</p>
                  </p>
                </div>
              ))}
            </div>
          </Card>
        </Col>
      </Row>

      <h2 className="text-gray-800 font-medium text-lg mb-5">New Bookings</h2>
      <Table
        dataSource={dashboardData.newBookings}
        columns={bookingsColumns}
        rowKey="_id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
        loading={loading}
      />

      <h2 className="text-gray-800 font-medium text-lg mb-5">Cancelled trips</h2>
      <Table
        dataSource={dashboardData.cancelledTrips}
        columns={cancelledTripsColumns}
        rowKey="_id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
        loading={loading}
      />

      <h2 className="text-gray-800 font-medium text-lg mb-5">Rescheduled trips</h2>
      <Table
        dataSource={dashboardData.recheduleTrips}
        columns={rescheduleTripsColumns}
        rowKey="_id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
        loading={loading}
      />


      <h2 className="text-gray-800 font-medium text-lg mb-5">All Bookings</h2>
      <Table
        dataSource={dashboardData.allBookings}
        columns={bookingsColumns}
        rowKey="_id"
        className="bg-white shadow-sm rounded-lg overflow-hidden"
        loading={loading}
      />
    </div>
  );
};

export default DashboardPage;