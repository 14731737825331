import React, { useRef, useState, useEffect } from 'react';
import { Table, Modal, Button, Input, Space, message, Form, Upload } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

const CategoryManagment = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [imageFile, setImageFile] = useState(null); // Image file state
  const [previewImage, setPreviewImage] = useState(null); // Preview of uploaded image
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/api/category/admin/all', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCategories(response.data);
    } catch (error) {
      message.error('Failed to fetch categories');
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate('/admin/login');
      } else {
        console.error('Error fetching category data:', error);
      }
    }
  };

  // Handling image upload changes
  // Handling image upload changes
  const handleImageChange = (info) => {
    const file = info.file.originFileObj || info.file; // Handle both manual and actual file objects

    if (file) {
      // Save the file for submission
      setImageFile(file);

      // Generate preview
      getBase64(file, (imageUrl) => {
        setPreviewImage(imageUrl);
      });
    }
  };


  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result)); // Convert image to base64
    reader.readAsDataURL(file); // Read the file as a data URL (base64)
  };
  

  const handleModalOpen = (category) => {
    setSelectedCategory(category);
    form.setFieldsValue(category);
    setIsModalVisible(true);
    setIsEditing(false);
    setImageFile(null);
    setPreviewImage(category?.image || null);
  };

  const handleSaveChanges = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('category', values.category);
      formData.append('id', selectedCategory._id);

      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axiosInstance.put(`/api/category/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      const updatedCategories = categories.map(category =>
        category._id === selectedCategory._id ? response.data : category
      );
      setCategories(updatedCategories);
      message.success('Category details updated successfully');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating category:', error);
      message.error('Failed to update category details');
    }
  };

  const handleAddOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      formData.append('category', values.category);

      if (imageFile) {
        formData.append('image', imageFile);
      }

      const response = await axiosInstance.post('/api/category/add', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setCategories([...categories, response.data]);
      setIsAddModalVisible(false);
      message.success('Category created successfully!');
    } catch (error) {
      console.error('Error adding category:', error);
      message.error('Failed to create category');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
  };

  const showRemoveConfirm = (category) => {
    setSelectedCategory(category);
    setIsDeleteModalVisible(true);
  };

  const handleRemoveCategory = async () => {
    if (!selectedCategory) return;

    try {
      await axiosInstance.delete(`/api/category/delete`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: { id: selectedCategory._id }, // Send ID in the request body
      });
      setCategories(categories.filter(category => category._id !== selectedCategory._id));
      message.success('Category removed successfully');
      setIsDeleteModalVisible(false);
      setSelectedCategory(null);
    } catch (error) {
      message.error('Failed to remove category');
    }
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 180 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 180 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0] || '');
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      width: '20%',
      ...getColumnSearchProps('_id'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: '20%',
      ...getColumnSearchProps('category'),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: '20%',
      render: (image) => (
        image ? <img src={`https://tripcurascobackend-production.up.railway.app${image}`} alt="Category" style={{ width: '50px', height: '50px', objectFit: 'cover' }} /> : 'No Image'
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleModalOpen(record)}>View Details</Button>
          <Button type="danger" onClick={() => showRemoveConfirm(record)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="category-management ml-52 pl-3 pr-3 pt-10">
      <div className='w-full pt-10 flex justify-between'>
        <h1 className="text-gray-800 font-medium text-xl">Category Management</h1>
        <Button type="primary" onClick={() => setIsAddModalVisible(true)}>Add New Category</Button>
      </div>

      <Table columns={columns} dataSource={categories} rowKey={(record) => record._id} />

      {/* Add Category Modal */}
      <Modal
        title="Add Category"
        visible={isAddModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please enter a category' }]}>
            <Input placeholder="Enter category name" />
          </Form.Item>

          <Form.Item label="Image">
            <Upload
              beforeUpload={() => false} // Prevent automatic upload
              onChange={handleImageChange} // Handle file selection and preview
              showUploadList={false} // Hide default file list
            >
              <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
            </Upload>

            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />
            )}
          </Form.Item>

        </Form>
      </Modal>

      {/* Edit/View Modal */}
      <Modal
        title="View Details"
        visible={isModalVisible}
        onOk={handleSaveChanges}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please enter a category' }]}>
            <Input disabled={!isEditing} placeholder="Enter category name" />
          </Form.Item>

          <Form.Item label="Image">
            <Upload
              beforeUpload={() => false}
              onChange={handleImageChange}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
            </Upload>
            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />
            )}
          </Form.Item>
        </Form>
      </Modal>

      {/* Delete Modal */}
      <Modal
        visible={isDeleteModalVisible}
        onOk={handleRemoveCategory}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText="Yes"
        okButtonProps={{ danger: true }}
        cancelText="No"
      >
        <p>Are you sure you want to delete this category?</p>
      </Modal>
    </div>
  );
};

export default CategoryManagment;
