import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import {
  AppstoreOutlined,
  EditOutlined,
  TeamOutlined,
  StarOutlined,
  BookOutlined,
  DashboardOutlined,
  UserOutlined,
  BorderBottomOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import Trips from '../pages/Admin-controls-pages/Trips';

const { Header, Content, Footer, Sider } = Layout;

const menuItems = [
  { icon: DashboardOutlined, label: 'Dashboard', path: 'dashboard' },
  { icon: AppstoreOutlined, label: 'Trips', path: 'trip' },
  { icon: BorderBottomOutlined, label: 'Category', path: 'category' },
  { icon: BookOutlined, label: 'Booking', path: 'booking' },
  { icon: TeamOutlined, label: 'Company Directory', path: 'companydirectory' },
  { icon: UserOutlined, label: 'User Management', path: 'usermanagment' },
  { icon: StarOutlined, label: 'Reviews', path: 'reviews' },
  { icon: EditOutlined, label: 'Edit Content', path: 'editcontent' },
].map((item, index) => ({
  key: item.path,  // Use the path as the key
  icon: React.createElement(item.icon),
  label: <Link to={item.path}>{item.label}</Link>,
}));

function AdminSidebar() {
  const location = useLocation();  // Get current location
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Get the path from the current location and find the corresponding menu item
  const selectedKey = menuItems.find(item => location.pathname.includes(item.key))?.key || 'dashboard';

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: colorBgContainer,
        }}
      >
        <div className='w-full flex justify-center items-center p-8'>
          <a href="" className="logo w-24 text-3xl text-theblue-400">
            TheTrip <br /> Curaçao
          </a>
        </div>

        <Menu 
          theme="light" 
          mode="inline" 
          selectedKeys={[selectedKey]}  // Set the selected menu item dynamically
          items={menuItems} 
        />
      </Sider>
      <Layout
        style={{
          marginLeft: 200,
          padding: '0 24px 24px',
          backgroundColor: '#FCF1DA'
        }}
      >
        <Content>
          <div>
            <Routes>
              {/* <Route path="trip" element={<Trips />} /> */}
              {/* Add your routes here */}
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminSidebar;
