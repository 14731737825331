import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingProvider, useLoading } from './Admin-controls-pages/LoadingContext';
import AdminSidebar from '../Components/AdminSidebar';
import Trips from './Admin-controls-pages/Trips';
import Bookings from './Admin-controls-pages/Bookings';
import ReviewManagment from './Admin-controls-pages/ReviewManagment';
import EditContent from './Admin-controls-pages/EditContent';
import CompanyDirectory from './Admin-controls-pages/CompanyDirectory';
import DashboardPage from './Admin-controls-pages/DashBoard';
import UserManagement from './Admin-controls-pages/UserManagment';
import CategoryManagment from './Admin-controls-pages/Category';

const Loader = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div className="loader-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#fff', // semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}>
      <div className="loader-content" style={{
        color: 'black',
        fontSize: '24px',
        fontWeight: 'bold',
      }}>
        Loading...
      </div>
    </div>
  );
};

function AdminContent() {
  const { setTimedLoader } = useLoading();

  useEffect(() => {
    // Show loader for 3 seconds when component mounts
    setTimedLoader(3000);
  }, [setTimedLoader]);

  return (
    <div>
      <AdminSidebar />
      <Loader />
      <Routes>
        <Route path="/trip" element={<Trips />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/" element={<DashboardPage />} />
        <Route path="/booking" element={<Bookings />} />
        <Route path="/reviews" element={<ReviewManagment />} />
        <Route path="/editcontent" element={<EditContent />} />
        <Route path="/companydirectory" element={<CompanyDirectory />} />
        <Route path="/usermanagment" element={<UserManagement />} />
        <Route path="/category" element={<CategoryManagment />} />
      </Routes>
    </div>
  );
}

function Admin() {
  return (
    <LoadingProvider>
      <AdminContent />
    </LoadingProvider>
  );
}

export default Admin;