import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useHistory, useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../API/authapi';

const ResetPassword = () => {

  const { token } = useParams(); // Extract the token from the URL

    const navigate = useNavigate();

//   const history = useHistory();

  const onFinish = async(values) => {
    try {

        const payload = {
            password:values.newpassword,
            token:token
        }

        console.log(payload);
        
        const response = await axiosInstance.post('/api/user/reset-password', payload);
        if(response.data){
          message.success('Your password has been updated');
          navigate('/login')
        }
        
      } catch (error) {
        message.error('Error sending reset link');
      }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
        <a className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
      <Card title="Forgot Password" className="w-full max-w-md mt-5">
        <Form
          name="forgot_password"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="New Password"
            name="newpassword"
            rules={[
              { required: true, message: 'Please enter a new password!' },
              { type: 'string', message: 'Please enter a new password!' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmpassword"
            rules={[
              { required: true, message: 'Please re-enter the above password!' },
              { type: 'string', message: 'Please re-enter the above password!' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;
