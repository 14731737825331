import React, { useState } from 'react'
import { Rate } from 'antd';

function ReviewBox({ review }) {
  const [showReply, setShowReply] = useState(false); // State to toggle reply visibility

  const toggleReply = () => {
    setShowReply(!showReply);
  };
    return (
      <div className='relative rounded-2xl border border-gray-400 p-4 flex flex-col gap-5 mt-4 pb-4'>
      <div className=' flex justify-between'>
        <div className=' flex gap-2'>
          <div>
            <p className='text-gray-800'>{review.user.username}</p>
            <p className=' text-sm text-gray-500'>{review.place}</p>
          </div>
        </div>
        <div className=' text-right '>
          <Rate disable defaultValue={review.rating} className=' w-25 -mr-5 scale-75' />
          <p className='text-xs md:text-sm text-gray-500'>{review.date}</p>
        </div>
      </div>

      <p className='text-sm md:text-base text-gray-600 font-light'>{review.reviewText}</p>
        {/* Toggle reply section */}
        <div className='w-full flex justify-end'>
        <p
          className='font-light cursor-pointer opacity-30 text-sm hover:opacity-95 transition-all'
          onClick={toggleReply}
        >
          {showReply ? 'Hide reply' : 'View reply'}
        </p>
      </div>

      {/* Conditionally render reply */}
      {showReply && (
        <div className='gap-2 text-right bg-white bg-opacity-95 p-3 rounded-xl shadow-sm'>
          <div className=' flex  justify-end gap-2'>
            {/* <UserOutlined/> */}
          <p >{review.replyAuthor}</p></div>
          <p className='text-sm md:text-base text-gray-600 font-light text-right'>
            {review.replyContent}
          </p>
        </div>
      )}
    </div>
  )
}

export default ReviewBox
