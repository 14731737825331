import axios from 'axios';

// Create an axios instance
export const axiosInstance = axios.create({
    baseURL: 'https://tripcurascobackend-production.up.railway.app/',
    withCredentials: true, // if you need to include credentials in requests
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            // Attach token to the headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);
