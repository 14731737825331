import React, { useState } from 'react';
import { Rate, Button, Modal } from 'antd';
import { axiosInstance } from '../API/authapi';

function AdminReview({ newReview, onPublish, onDelete }) {
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [replyAuthor, setReplyAuthor] = useState('');


  const showReplyModal = () => {
    setIsReplyModalVisible(true);
  };

  const handleReplyOk = async () => {
    try {
      await axiosInstance.put(`/api/rating/reviews/${newReview._id}/reply`, {
        replyAuthor,
        replyContent,
      });
      setIsReplyModalVisible(false);
      // Optionally refresh the review or handle UI updates after a successful reply
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
  };

  const handleReplyCancel = () => {
    setIsReplyModalVisible(false);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteOk = async () => {
    try {
      await axiosInstance.delete(`/api/rating/reviews/${newReview._id}`);
      onDelete(newReview._id); // Call onDelete prop with review id
      setIsDeleteModalVisible(false);
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  const handlePublish = async (status) => {
    try {
      await axiosInstance.put(`/api/rating/reviews/${newReview._id}`, {
        status,
      });
      onPublish(newReview._id); // Call onPublish prop with review id
    } catch (error) {
      console.error('Error publishing/unpublishing review:', error);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div className='bg-white rounded-2xl p-4 flex flex-col gap-5 mt-4'>
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          <img src={newReview.profile} alt="" className='w-12 rounded-full' />
          <div>
            <p className='text-gray-800'>
              {newReview.user ? newReview.user.username : 'Unknown User'}
            </p>
          </div>
        </div>
        <div className='text-left'>
          <Rate disabled defaultValue={newReview.rating} />
          <p className='text-sm text-gray-500'>{newReview.date}</p>
        </div>
      </div>
      <div>
        <p className='text-sm text-gray-800'>{newReview.user ? newReview.user.email : 'no email'}</p>
        <p className='text-sm text-gray-800'>{newReview.trip ? newReview.trip.tripname : 'unkown trip'}</p>
      </div>
      <p className='text-sm md:text-base text-gray-600 font-light'>{newReview.reviewText}</p>
      <div className='w-full flex justify-end gap-3'>
        <Button type='tertiary' onClick={showReplyModal}>Reply</Button>
        <Button type='' onClick={showDeleteModal}>Delete</Button>
        {newReview.status === 'published' || newReview.status === 'new' ? (
          <Button style={{ backgroundColor: 'black', color: 'white' }} onClick={() => handlePublish('hidden')}>
            Hide
          </Button>
        ) : (
          <Button type='primary' onClick={() => handlePublish('published')}>
            Publish
          </Button>
        )}


      </div>

      {/* Reply Modal */}
      <Modal
        title="Reply to Review"
        visible={isReplyModalVisible}
        onOk={handleReplyOk}
        onCancel={handleReplyCancel}
      >
        <input
          type="text"
          value={replyAuthor}
          onChange={(e) => setReplyAuthor(e.target.value)}
          placeholder="Author name"
          className="w-full border border-gray-300 p-2 rounded mb-4"
        />
        {/* Reply form */}
        <textarea
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder="Write your reply here..."
          rows={4}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okText="Yes, Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this review?</p>
      </Modal>
    </div>
  );
}

export default AdminReview;
