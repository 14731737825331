import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Modal, notification, AutoComplete, Select, Button, message, Typography, Pagination } from 'antd';
import moment from 'moment';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';
import { CalendarFilled } from '@ant-design/icons';

const { Option } = Select;

const Bookings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [rescheduleModalVisible, setRescheduleModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [deleteLoadingId, setDeleteLoadingId] = useState(null);
  const [form] = Form.useForm();
  const [cancelReason, setCancelReason] = useState('');
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(8);
  const [filterStatus, setFilterStatus] = useState('All');
  const [filterTripStatus, setFilterTripStatus] = useState('All');
  const [sortKey, setSortKey] = useState('');
  const [lastCancelledBooking, setLastCancelledBooking] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0);
  // Cancelled bookings
  const [cancelledBookings, setCancelledBookings] = useState([]);

  const [bookings, setBookings] = useState([]);
  const [filteredAllBookings, setFilteredAllBookings] = useState(bookings);
  // Booking data
  const navigate = useNavigate();

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getPaymentClass = (status) => {
    switch (status) {
      case "paid":
        return "bg-blue-100 text-blue-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "failed":
        return "bg-red-100 text-red-800";
      case "refunded":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getTripStatusClass = (status) => {
    switch (status) {
      case "payment pending":
        return "bg-yellow-100 text-yellow-800";
      case "confirmed":
        return "bg-blue-100 text-blue-800";
      case "in progress":
        return "bg-purple-100 text-purple-800";
      case "completed":
        return "bg-green-100 text-green-800";
      case "cancelled":
        return "bg-red-100 text-red-800";
      case "Refunded":
        return "bg-green-100 text-green-800";
      case "Refund Rejected":
        return "bg-red-100 text-red-800";
      case "Reschedule Rejected":
        return "bg-orange-100 text-orange-800";
      case "Requested Reschedule":
        return "bg-indigo-100 text-indigo-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const fetchBookings = async () => {  
    try {
      const response = await axiosInstance.get('/api/checkout/bookings', {
        params: {
          page: currentPage,
          limit: pageSize,
          search: searchText,
          paymentStatus: filterStatus,
          tripStatus: filterTripStatus,
          sort: sortKey,
        },
      });
  
      setBookings(response.data.bookings);
      setTotalPages(response.data.totalPages);
      setTotalBookings(response.data.totalBookings);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to admin login if unauthorized
        navigate('/admin/login');
      } else {
        // Handle other errors (e.g., show a toast or log the error)
        console.error(error.message);
      }
    }
  };
  


  // Update useEffect to call fetchBookings
  useEffect(() => {
    fetchBookings();
  }, [currentPage, pageSize, searchText, filterStatus, filterTripStatus, sortKey]);






  const { confirm } = Modal; // Destructure confirm from Modal

  const showModal = (booking) => {
    confirm({
      title: 'Are you sure you want to delete this booking?',
      content: `Booking for ${booking.bookingcode} will be permanently deleted.`,
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => {
        setDeleteLoadingId(booking._id); // Track which booking is being deleted
        return axiosInstance.delete(`/api/checkout/bookings/${booking._id}`)
          .then(response => {
            if (response.status === 204) {
              const updatedBookings = bookings.filter((b) => b._id !== booking._id);
              setBookings(updatedBookings);
              setFilteredAllBookings(updatedBookings);
              message.success('Booking deleted successfully');
            }
          })
          .catch(error => {
            console.error('Error deleting booking:', error);
            message.error('Failed to delete booking');
          })
          .finally(() => setDeleteLoadingId(null));
      },
      onCancel() {
        setDeleteLoadingId(null);
      },
    });
  };

  const showEditModal = (booking) => {
    setSelectedBooking(booking);
    form.setFieldsValue({
      ...booking,
      date: moment(booking.date, 'YYYY-MM-DD'),
    });
    setEditModalVisible(true);
  };

  const showRescheduleModal = (booking) => {
    setSelectedBooking(booking);
    form.setFieldsValue({
      tripStart: moment(booking.tripStart, 'YYYY-MM-DD'),
      tripEnd: moment(booking.tripEnd, 'YYYY-MM-DD'),
    });
    setRescheduleModalVisible(true);
  };

  const handleModalOk = () => {
    if (cancelReason.trim() === '') {
      alert('Please provide a reason for removal.');
      return;
    }

    // Remove the booking and move to cancelled bookings
    const updatedBookings = bookings.filter((booking) => booking.id !== selectedBooking.id);
    setBookings(updatedBookings);
    setFilteredAllBookings(updatedBookings);

    const cancelledBooking = { ...selectedBooking, cancelReason };
    setCancelledBookings([...cancelledBookings, cancelledBooking]);
    setLastCancelledBooking(cancelledBooking);

    // Show notification
    notification.success({
      message: 'Booking successfully removed',
    });

    setIsModalVisible(false);
    setCancelReason(''); // Clear the reason
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCancelReason(''); // Clear the reason
  };


  const handleEditModalOk = () => {
    form.validateFields()
      .then(async (values) => {
        setEditLoading(true);
        try {
          const updatedBooking = {
            ...selectedBooking,
            ...values,
          };

          const response = await axiosInstance.put(
            `/api/checkout/bookings/${updatedBooking._id}`,
            updatedBooking
          );

          if (response.status === 200) {
            const updatedBookings = bookings.map((booking) =>
              booking._id === updatedBooking._id ? updatedBooking : booking
            );
            setBookings(updatedBookings);
            setFilteredAllBookings(updatedBookings);
            setEditModalVisible(false);
          }
        } catch (error) {
          console.error('Error updating booking:', error);
        } finally {
          setEditLoading(false);
        }
      })
      .catch((info) => {
        setEditLoading(false);
        console.log('Validate Failed:', info);
      });
  };


  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };


  const handleRescheduleModalOk = () => {
    form.validateFields().then(async (values) => {
      setRescheduleLoading(true);
      try {
        const updatedBooking = {
          date: values.date.format('YYYY-MM-DD'),
        };

        const response = await axiosInstance.put(
          `/api/checkout/bookings/${selectedBooking._id}`,
          updatedBooking
        );

        if (response.status === 200) {
          const updatedBookings = bookings.map((booking) =>
            booking._id === selectedBooking._id ? { ...booking, ...updatedBooking } : booking
          );
          setBookings(updatedBookings);
          setFilteredAllBookings(updatedBookings);
          setRescheduleModalVisible(false);
          message.success('Booking rescheduled successfully');
        }
      } catch (error) {
        message.error('Failed to reschedule booking');
      } finally {
        setRescheduleLoading(false);
      }
    }).catch((info) => {
      setRescheduleLoading(false);
      console.log('Validate Failed:', info);
    });
  };


  const handleRescheduleModalCancel = () => {
    setRescheduleModalVisible(false);
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = bookings.filter(booking => booking.bookingCode.includes(value));
    setFilteredAllBookings(filtered);
  };

  const handleFilterChange = (value, type) => {
    if (type === 'payment') {
      setFilterStatus(value);
    } else if (type === 'trip') {
      setFilterTripStatus(value);
    }
    setCurrentPage(1); // Reset to first page when filters change
  };



  const handleSortChange = (value) => {
    setSortKey(value);
    setCurrentPage(1); // Reset to first page when sorting changes
  };



  return (
    <main className='ml-56'>
      <section className="mt-5">
        <h1>All Bookings</h1>
        <div className="flex gap-4 mb-4">
          <AutoComplete
            style={{ width: 200 }}
            value={searchText}
            onChange={(value) => {
              setSearchText(value);
              setCurrentPage(1);
            }}
            onSearch={(value) => setSearchText(value)}
            placeholder="Search booking code..."
          />
          <div>
            <Typography.Text strong>Payment Status</Typography.Text>
            <Select
              style={{ width: 200, marginLeft: 8 }}
              value={filterStatus}
              onChange={(value) => handleFilterChange(value, 'payment')}
              placeholder="Payment Status"
            >
              <Option value="All">All</Option>
              <Option value="pending">Pending</Option>
              <Option value="paid">Paid</Option>
              <Option value="failed">Failed</Option>
              <Option value="refunded">Refunded</Option>
            </Select>
          </div>

          <div >
            <Typography.Text strong>Trip Status</Typography.Text>
            <Select
              style={{ width: 200, marginLeft: 8 }}
              value={filterTripStatus}
              onChange={(value) => handleFilterChange(value, 'trip')}
              placeholder="Trip Status"
            >
              
              <Option value="All">All</Option>
              <Option value="payment pending">Payment Pending</Option>
              <Option value="confirmed">Confirmed</Option>
              <Option value="in progress">In Progress</Option>
              <Option value="completed">Completed</Option>
              <Option value="cancelled">Cancelled</Option>
              <Option value="Requested Cancellation">Requested Cancellation</Option>
              <Option value="Cancellation Rejected">Cancellation Rejected</Option>
              <Option value="Refunded">Refunded</Option>
              <Option value="Refund Rejected">Refund Rejected</Option>
              <Option value="Reschedule Rejected">Reschedule Rejected</Option>
              <Option value="Requested Reschedule">Requested Reschedule</Option>
              <Option value="Requested Refund">Requested Refund</Option>
            </Select>
          </div>

          <Select
            style={{ width: 200 }}
            onChange={handleSortChange}
            placeholder="Sort By"
          >
            <Option value="name">Name (A-Z)</Option>
            <Option value="tripStart">Trip Date (Newest)</Option>
            <Option value="totalPrice">Price (High-Low)</Option>
          </Select>

        </div>
        <div className="activities-list w-full flex gap-6 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">
          {bookings.map((activity) => (
            <div key={activity.id} className="flex-1 min-w-[300px] max-w-[340px] w-[300px]">
              <div className="bg-white border border-gray-200 rounded-2xl p-4 transition-all relative shadow-md hover:shadow-lg">
                <div className="relative group">
                  <img
                    className="w-full h-44 object-cover rounded-xl"
                    src={`https://tripcurascobackend-production.up.railway.app/${activity.trip.mainphoto}`}
                    alt="activity"
                  />
                </div>

                <div className="mt-4">
                  <h2 className="text-lg font-semibold leading-tight text-gray-800 truncate">
                    {activity.trip.tripname}
                  </h2>
                  <p className="text-sm text-gray-600">by {activity.trip.company}</p>
                </div>

                <div className="mt-4">
                  <p className="text-sm font-semibold text-gray-800 bg-blue-50 rounded-lg p-2 shadow-inner">
                    Booking Code: <span className="text-blue-600">{activity.bookingCode}</span>
                  </p>
                  <p className="flex items-center gap-2 mt-3 text-sm text-gray-800">
                    <CalendarFilled className="w-4 h-4 text-blue-600" />
                    <span className="font-semibold">Trip Date: </span>
                    <span className="text-blue-600">{formatDate(activity.date)}</span>
                  </p>
                  <p className="mt-2">
                    <span className="font-semibold">Trip Status: </span>
                    <span className={`px-2 py-1 rounded ${getTripStatusClass(activity.tripStatus)}`}>
                      {activity.tripStatus}
                    </span>
                  </p>

                  <p className="mt-1">
                    <span className="font-semibold">Payment Status: </span>
                    <span className={`px-2 py-1 rounded ${getPaymentClass(activity.paymentStatus)}`}>
                      {activity.paymentStatus}
                    </span>
                  </p>
                  <p className="text-sm text-gray-800 mt-2">
                    <span className="font-medium">Customer Name: </span>{activity.fullName}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Mail ID: </span>{activity.email}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Phone: </span>{activity.phone}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment Method: </span>{activity.paymentMethod}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Total Price: </span>${activity.totalPrice}
                  </p>

         
                  {activity.reschedule_date && (
                    <p className="text-sm text-red-600">
                      <span className="font-medium">Rescheduled Date: </span>{formatDate(activity.reschedule_date)}
                    </p>
                  )}

                 

                  <p className="text-sm text-gray-800 mt-2">
                    <span className="font-medium">Booking Date: </span>{formatDate(activity.createdAt)}
                  </p>

                  <div className="flex gap-3 mt-4">
                    <button
                      onClick={() => showEditModal(activity)}
                      className="bg-blue-500 w-full text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      disabled={editLoading && activity._id === selectedBooking?._id}
                    >
                      {editLoading && activity._id === selectedBooking?._id ? 'Saving...' : 'Edit'}
                    </button>

                    <button
                      onClick={() => showModal(activity)}
                      className="bg-red-500 w-full text-white px-4 py-2 rounded-md hover:bg-red-600"
                      disabled={deleteLoadingId === activity._id}
                    >
                      {deleteLoadingId === activity._id ? 'Deleting...' : 'Delete'}
                    </button>

                    <button
                      onClick={() => showRescheduleModal(activity)}
                      className="bg-green-500 w-full text-white px-4 py-2 rounded-md hover:bg-green-600"
                      disabled={rescheduleLoading && activity._id === selectedBooking?._id}
                    >
                      {rescheduleLoading && activity._id === selectedBooking?._id ? 'Rescheduling...' : 'Reschedule'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center mt-4 mb-5">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalBookings}
            showSizeChanger
            pageSizeOptions={['8', '16', '24', '32']}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} bookings`
            }
          />
        </div>

      </section>
      <Modal
        title="Confirm Removal"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to cancel this booking?</p>
        <Input.TextArea
          rows={4}
          value={cancelReason}
          onChange={handleCancelReasonChange}
          placeholder="Please provide a reason for removal"
        />
      </Modal>
      <Modal
        title="Edit Booking"
        visible={editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{ loading: editLoading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Customer Name" name="fullName" rules={[{ required: true, message: 'Please enter customer name' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Mail ID" name="email" rules={[{ required: true, message: 'Please enter mail ID' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please enter phone number' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Payment Method" name="paymentMethod" rules={[{ required: true, message: 'Please enter payment method' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Trip Status"
            name="tripStatus"
            rules={[{ required: true, message: 'Please select a trip status' }]}
          >
            <Select
              placeholder="Select trip status"
              // Optionally set initial value if needed
              // defaultValue="pending"
              style={{ width: '100%' }}
            >
              <Option value="All">All</Option>
              <Option value="payment pending">Payment Pending</Option>
              <Option value="confirmed">Confirmed</Option>
              <Option value="in progress">In Progress</Option>
              <Option value="completed">Completed</Option>
              <Option value="cancelled">Cancelled</Option>
              <Option value="Refunded">Refunded</Option>
              <Option value="Refund Rejected">Refund Rejected</Option>
              <Option value="Reschedule Rejected">Reschedule Rejected</Option>
              <Option value="Requested Reschedule">Requested Reschedule</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Payment Status"
            name="paymentStatus"
            rules={[{ required: true, message: 'Please select a payment status' }]}
          >
            <Select
              placeholder="Select payment status"
              // Optionally set initial value if needed
              // defaultValue="pending"
              style={{ width: '100%' }}
            >
              <Option value="pending">Pending</Option>
              <Option value="paid">Paid</Option>
              <Option value="failed">Failed</Option>
              <Option value="refunded">Refunded</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Total Price" name="totalPrice" rules={[{ required: true, message: 'Please enter total price' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reschedule Booking"
        visible={rescheduleModalVisible}
        onOk={handleRescheduleModalOk}
        onCancel={handleRescheduleModalCancel}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{ loading: rescheduleLoading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Trip Date" name="date" rules={[{ required: true, message: 'Please select trip start date' }]}>
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  );
};

export default Bookings;
