import React from 'react';
import CompareInfoBox from './CompareInfoBox';
import { Link } from 'react-router-dom';

function CompareModal({ trips }) {
  return (
    <main className="flex gap-4 ">
      {/* <div className=' mt-52 flex flex-col gap-2 font-bold'>
        <p>Description</p>
        <p>Title</p>
        <p className=' text-nowrap'>Time & Price</p>
        <p>Do this because</p>
        <p className=' mt-8'>Features</p>
      </div> */}
      <div className='flex'>
        {trips.map((trip, index) => {
          const suitableForItems = [
            { label: 'Group', icon: '../../assets/svg/product-page-box/group.svg', isAvailable: trip.suitable_for_couple },
            { label: 'Single', icon: '../../assets/svg/product-page-box/single.svg', isAvailable: trip.suitable_for_single },
            { label: 'Children', icon: '../../assets/svg/product-page-box/children.svg', isAvailable: trip.suitable_for_children }
          ];

          const includedItems = [
            { label: 'Photos', icon: '../../assets/svg/product-page-box/photos.svg', isAvailable: trip.include_photos },
            { label: 'Video', icon: '../../assets/svg/product-page-box/video.svg', isAvailable: trip.include_videos },
            { label: 'Water', icon: '../../assets/svg/product-page-box/water.svg', isAvailable: trip.include_water }
          ];

          const presentItems = [
            { label: 'Required equipment', icon: '../../assets/svg/product-page-box/requiredequipments.svg', isAvailable: trip.present_req_equipment },
            { label: 'Chairs', icon: '../../assets/svg/product-page-box/chair.svg', isAvailable: trip.present_chairs },
            { label: 'WC', icon: '../../assets/svg/product-page-box/wc.svg', isAvailable: trip.present_wc }
          ];

          const languagesItems = [
            { label: 'English', icon: '../../assets/svg/product-page-box/language.svg', isAvailable: trip.languages_english },
            { label: 'Dutch', icon: '../../assets/svg/product-page-box/language.svg', isAvailable: trip.languages_dutch },
            { label: 'Papiamento', icon: '../../assets/svg/product-page-box/language.svg', isAvailable: trip.languages_papiamentu },
            { label: 'Spanish', icon: '../../assets/svg/product-page-box/language.svg', isAvailable: trip.languages_spanish }
          ];

          const whatToBringItems = trip.what_to_bring?.map(item => ({
            label: item
          })) || [];

          return (
            <div key={index} className="p-4 flex flex-col gap-2 w-64 md:w-full border-l border-theorange-400 z-0">

              <div className=' relative'>
                <div className=' bg-white p-3 pt-2 pb-2 shadow-md flex absolute rounded-lg top-2 right-2 gap-2'>{trip.review} <img src="../../assets/svg/star.svg" alt="" /></div>
                <img className="w-full h-44 object-cover rounded-xl" src={`https://tripcurascobackend-production.up.railway.app/${trip.mainphoto}`} alt={`${trip.tripname}`} />
                <Link to={`/trip/${trip._id}`} className='mybutton absolute bottom-2 right-2 text-white p-3 rounded-lg pt-2 pb-2'>Book now</Link>
              </div>
              <p className="text-sm border-b border-theorange-400 border-opacity-50 line-clamp">
                {trip.about}
              </p>
              <h2 className="text-sm border-b border-theorange-400 border-opacity-50">{trip.tripname}, {trip.location}</h2>
              <p className="text-sm border-b border-theorange-400 border-opacity-50">$ {trip.price}</p>
              <ul className='text-xs font-light md:text-sm list-inside border-b border-theorange-400 border-opacity-50'>
                {trip.do_this_because.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
                <CompareInfoBox title="Suitable for" items={suitableForItems} />
                <CompareInfoBox title="Included" items={includedItems} />
                <CompareInfoBox title="Present" items={presentItems} />
                <CompareInfoBox title="Available languages" items={languagesItems} />
                <CompareInfoBox title="What to bring" items={whatToBringItems} hideIcons={true} />
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
}

export default CompareModal;
