import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Homepage';
import TripPage from './pages/TripPage';
import LocalExperience from './pages/LocalExperience';
import './App.css'
import Activities from './pages/Activities';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PartywithLocal from './pages/PartywithLocal';
import KumindadikasExperience from './pages/KumindadikasExperience';
import LeisurewithLocals from './pages/LeisurewithLocals';
import Beaches from './pages/Beaches';
import About from './pages/About';
import MyAccount from './pages/MyAccount';
import Admin from './pages/Admin';
import CheckoutPage from './pages/CheckoutPage';
import TermConditions from './pages/TermConditions';
import Disclaimer from './pages/Disclaimer';
import Cookies from './pages/Cookies';
import HowWeWork from './pages/HowWeWork';
import AdminLogin from './pages/AdminLogin';
import LogIn from './pages/LogIn';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import BookingConfirmed from './pages/BookingConfirmed';
import ResetPassword from './pages/ResetPassword';

function App() {
  const trip = {
    name: 'Sample Trip',
    images: ['image1.jpg', 'image2.jpg'],
  };
  const startDate = '2024-07-01';
  const endDate = '2024-07-10';
  const finalTotal = 500;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/trip/:id" element={<TripPage />} />
        <Route path="/local-experience" element={<LocalExperience />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/partywithlocal" element={<PartywithLocal />} />
        <Route path='/kumindadikasExperience' element={<KumindadikasExperience/>}/>
        <Route path='/leisurewithlocals' element={<LeisurewithLocals/>}/>
        <Route path='/beaches' element={<Beaches/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/account' element={<MyAccount/>}/>
        <Route path='/admin/*' element={<Admin/>}/>
        <Route path='/disclaimer' element={<Disclaimer/>}/>
        <Route path='/checkout' element={<CheckoutPage/>}/>
        <Route path='/termsconditions' element={<TermConditions/>}/>
        <Route path='/cookies' element={<Cookies/>}/>
        <Route path='/howwework' element={<HowWeWork/>}/>
        <Route path='/admin/login' element={<AdminLogin/>}/>
        <Route path='/login' element={<LogIn/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/forgotpassword' element={<ForgotPassword/>}/>
        <Route path='/resetpassword/:token' element={<ResetPassword/>}/>
        <Route path='/bookingconfirm' element={<BookingConfirmed/>}/>
      </Routes>
    </Router>
  );
}

export default App;
