import React, { useState, useEffect } from 'react';
import Dropdown from 'antd/es/dropdown/dropdown';
import CurrencyChange from './CurrencyChange';
import { useNavigate } from 'react-router-dom';
import { CurrencyProvider } from './CurrencyContext';

function BlueHeader() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState('Guest');

  const navigate = useNavigate();

  const toggleMenu = () => {

    setMenuOpen(!menuOpen);

  };



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setLoggedIn(!!token); // Set loggedIn based on token presence
      setUserName(localStorage.getItem('username'))
    };

    window.addEventListener('scroll', handleScroll);
    checkLoginStatus(); // Check login status on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    localStorage.removeItem('token'); // Remove the token from local storage
    setLoggedIn(false); // Update login status
    navigate('/login'); // Redirect to the login page
  };

  const items = [
    { 
      key: '1',
      label: (
        <a rel="noopener noreferrer" href="/account">
          {loggedIn ? userName : 'Guest'}
        </a>
      ),
    },
    !loggedIn ? { // Show only if not logged in
      key: '2',
      label: (
        <a href="/login">
          Sign in
        </a>
      ),
    } : null,
    loggedIn ? { // Show only if logged in
      key: '3',
      label: (
        <a
          href="/logout"
          onClick={handleLogout} // Call the logout handler
        >
          Log out
        </a>
      ),
    } : null,
  ].filter(item => item !== null); // Filter out null items

  return (
    <CurrencyProvider>
    <div>
      <header className={`w-full p-8 pb-5 pt-5 flex justify-between fixed top-0 z-20 transition-all duration-300 ${isScrolled ? 'bg-white text-theblue-400 bg-opacity-75 backdrop-blur' : 'text-white'}`}>
        <a href='/' className='logo w-24 text-3xl'>TheTrip <br />Curaçao</a>
        <label className="hamburger md:hidden z-50">
          <input type="checkbox" checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} />
          {/* Phone menu icon */}
          <svg viewBox="0 0 32 32" className={` ${isScrolled ? 'text-theblue-400' : 'text-white'}`} xmlns="http://www.w3.org/2000/svg">
            <path className="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22" stroke="currentColor"></path>
            <path className="line" d="M7 16 27 16" stroke="currentColor"></path>
          </svg>

        </label>
        <nav className="hidden md:block py-4 font-normal">
          <ul className="flex justify-center space-x-8">
            <li>
              <a href="/" className='transition-all hover:opacity-55 hover:text-sm'>Home</a>
            </li>
            <li>
              <a href="/activities" className='transition-all hover:opacity-55 hover:text-sm'>Activities</a>
            </li>
            <li>
              <a href="/local-experience" className='transition-all hover:opacity-55 hover:text-sm'>Local Experience</a>
            </li>
            <li>
              <a href="/about" className='transition-all hover:opacity-55 hover:text-sm'>About Us</a>
            </li>
            <CurrencyChange />
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              <li>
                <a href="#activities">
                  <svg className='fill-theblue-400' width="28" height="28" viewBox="0 0 39 39" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.5 35.3438C22.2814 35.3478 25.0145 34.6162 27.4219 33.2231V28.0312C27.4219 26.5767 26.8441 25.1817 25.8155 24.1532C24.787 23.1247 23.392 22.5469 21.9375 22.5469H17.0625C15.608 22.5469 14.213 23.1247 13.1845 24.1532C12.1559 25.1817 11.5781 26.5767 11.5781 28.0312V33.2231C13.9855 34.6162 16.7186 35.3478 19.5 35.3438ZM31.0781 28.0312V30.3152C33.1858 28.0588 34.5883 25.2357 35.1132 22.193C35.638 19.1503 35.2624 16.0205 34.0324 13.1884C32.8024 10.3563 30.7717 7.94531 28.1899 6.25181C25.608 4.55831 22.5877 3.6561 19.5 3.6561C16.4123 3.6561 13.392 4.55831 10.8101 6.25181C8.22833 7.94531 6.19762 10.3563 4.96762 13.1884C3.73763 16.0205 3.36197 19.1503 3.88682 22.193C4.41167 25.2357 5.81416 28.0588 7.92188 30.3152V28.0312C7.92186 26.1468 8.50398 24.3083 9.58864 22.7672C10.6733 21.2262 12.2075 20.0579 13.9815 19.422C13.0611 18.3633 12.4646 17.0622 12.2632 15.6739C12.0619 14.2855 12.2641 12.8686 12.8458 11.592C13.4275 10.3154 14.3642 9.23311 15.544 8.47417C16.7239 7.71524 18.0971 7.31171 19.5 7.31171C20.9029 7.31171 22.2761 7.71524 23.456 8.47417C24.6358 9.23311 25.5725 10.3154 26.1542 11.592C26.7359 12.8686 26.9381 14.2855 26.7368 15.6739C26.5354 17.0622 25.9389 18.3633 25.0185 19.422C26.7925 20.0579 28.3267 21.2262 29.4114 22.7672C30.496 24.3083 31.0781 26.1468 31.0781 28.0312ZM19.5 39C24.6717 39 29.6316 36.9455 33.5342 33.0424C37.4369 29.1393 39.5 24.1794 39.5 19C39.5 13.8206 37.4369 8.86074 33.5342 4.95764C29.6316 1.05454 24.6717 -1 19.5 -1C14.3283 -1 9.36841 1.05454 5.46578 4.95764C1.56315 8.86074 -1 13.8206 -1 19C-1 24.1794 1.56315 29.1393 5.46578 33.0424C9.36841 36.9455 14.3283 39 19.5 39Z" fill="currentColor"></path>
                  </svg>
                </a>
              </li>
            </Dropdown>
          </ul>
        </nav>
        {menuOpen && (
          <nav className="md:hidden fixed top-0 left-0 w-full h-screen bg-white bg-opacity-95 flex flex-col items-center justify-center space-y-8 z-10 backdrop-blur-lg">
            <a href='/' className='logo w-24 text-3xl text-theblue-400'>TheTrip <br />Curaçao</a>
            <a href="/" className='text-slate-900 text-xl' onClick={toggleMenu}>Home</a>
            <a href="/activities" className='text-slate-900 text-xl' onClick={toggleMenu}>Activities</a>
            <a href="/local-experience" className='text-slate-900 text-xl' onClick={toggleMenu}>Local Experience</a>
            <a href="/about" className='text-slate-900 text-xl' onClick={toggleMenu}>About Us</a>
            <a href="/account" className='text-slate-900 text-xl' onClick={toggleMenu}>{loggedIn? userName: 'Guest'}</a>
            <div className=' w-full flex justify-center gap-5 items-center text-gray-500 underline'>
              <a href="/privacy">Help Desk</a>
              <a href="/privacy">Cookies managment</a>
              
            </div>
            <CurrencyChange />
          </nav>
          
        )}
      </header>
    </div>
    </CurrencyProvider>
  );
}

export default BlueHeader;
