import React, { useEffect, useState } from 'react';
import { DatePicker, Form, Input, Modal, notification, AutoComplete, Select, Button, message } from 'antd';
import moment from 'moment';
import { axiosInstance } from '../../API/authapi';

const { Option } = Select;

const Bookings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [rescheduleModalVisible, setRescheduleModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [form] = Form.useForm();
  const [cancelReason, setCancelReason] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');
  const [sortKey, setSortKey] = useState('');
  const [lastCancelledBooking, setLastCancelledBooking] = useState(null);

  // Booking data
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    // Fetch bookings data from API
    axiosInstance.get('/api/checkout/bookings')
      .then(response => {
        if (response.status === 101) {
          // Clear token from local storage and redirect to login
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        } else {
          setBookings(response.data);
          setFilteredAllBookings(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);

        // Check if it's a 101 error or a different one
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        } else {
          notification.error({
            message: error.message,
          });
        }
      });
  }, []);


  // Cancelled bookings
  const [cancelledBookings, setCancelledBookings] = useState([]);

  const [filteredAllBookings, setFilteredAllBookings] = useState(bookings);


  const { confirm } = Modal; // Destructure confirm from Modal

  const showModal = (booking) => {
    // Show confirmation modal before deleting
    confirm({
      title: 'Are you sure you want to delete this booking?',
      content: `Booking for ${booking.tripname} will be permanently deleted.`,
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          // Send DELETE request to the API
          const response = await axiosInstance.delete(`/api/checkout/bookings/${booking._id}`);

          if (response.status === 204) {
            // Filter out the deleted booking from the list
            const updatedBookings = bookings.filter((b) => b.id !== booking.id);
            setBookings(updatedBookings);
            setFilteredAllBookings(updatedBookings);

            message.success('Booking deleted successfully');
          }
        } catch (error) {
          console.error('Error deleting booking:', error);
          message.error('Failed to delete booking');
        }
      },
      onCancel() {
        console.log('Delete action cancelled');
      },
    });
  };

  const showEditModal = (booking) => {
    setSelectedBooking(booking);
    form.setFieldsValue({
      ...booking,
      date: moment(booking.date, 'YYYY-MM-DD'),
    });
    setEditModalVisible(true);
  };

  const showRescheduleModal = (booking) => {
    setSelectedBooking(booking);
    form.setFieldsValue({
      tripStart: moment(booking.tripStart, 'YYYY-MM-DD'),
      tripEnd: moment(booking.tripEnd, 'YYYY-MM-DD'),
    });
    setRescheduleModalVisible(true);
  };

  const handleModalOk = () => {
    if (cancelReason.trim() === '') {
      alert('Please provide a reason for removal.');
      return;
    }

    // Remove the booking and move to cancelled bookings
    const updatedBookings = bookings.filter((booking) => booking.id !== selectedBooking.id);
    setBookings(updatedBookings);
    setFilteredAllBookings(updatedBookings);

    const cancelledBooking = { ...selectedBooking, cancelReason };
    setCancelledBookings([...cancelledBookings, cancelledBooking]);
    setLastCancelledBooking(cancelledBooking);

    // Show notification
    notification.success({
      message: 'Booking successfully removed',
    });

    setIsModalVisible(false);
    setCancelReason(''); // Clear the reason
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setCancelReason(''); // Clear the reason
  };


  const handleEditModalOk = () => {
    form.validateFields()
      .then(async (values) => {
        const updatedBooking = {
          ...selectedBooking,
          ...values,
        };

        try {
          // Send PUT request to update the booking via the API
          const response = await axiosInstance.put(`/api/checkout/bookings/${updatedBooking._id}`, updatedBooking);

          if (response.status === 200) {
            // Update booking in the state if the API call is successful
            const updatedBookings = bookings.map((booking) =>
              booking._id === updatedBooking._id ? updatedBooking : booking
            );
            setBookings(updatedBookings);
            setFilteredAllBookings(updatedBookings);
            setEditModalVisible(false);
          }
        } catch (error) {
          console.error('Error updating booking:', error);
          // Handle error (e.g., show error message to the user)
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };


  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };


  const handleRescheduleModalOk = () => {
    form.validateFields().then(async (values) => {
      try {
        const updatedBooking = {
          date: values.date.format('YYYY-MM-DD'), // Format the date as required
        };

        // Call the reschedule API with a PUT request
        const response = await axiosInstance.put(`/api/checkout/bookings/${selectedBooking._id}`, updatedBooking);

        if (response.status === 200) {
          // Update the booking in local state
          const updatedBookings = bookings.map((booking) =>
            booking._id === selectedBooking._id ? { ...booking, ...updatedBooking } : booking
          );

          // Update state with the rescheduled bookings
          setBookings(updatedBookings);
          setFilteredAllBookings(updatedBookings);

          // Hide the modal
          setRescheduleModalVisible(false);

          // Show success message
          message.success('Booking rescheduled successfully');
        }
      } catch (error) {
        console.error('Error rescheduling booking:', error);
        message.error('Failed to reschedule booking');
      }
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
  };


  const handleRescheduleModalCancel = () => {
    setRescheduleModalVisible(false);
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = bookings.filter(booking => booking.bookingCode.includes(value));
    setFilteredAllBookings(filtered);
  };

  const handleFilterChange = (value) => {
    setFilterStatus(value);
    let filtered = bookings;
    if (value !== 'All') {
      filtered = bookings.filter(booking => booking.paymentStatus === value);
    }
    setFilteredAllBookings(filtered);
  };

  const handleSortChange = (value) => {
    setSortKey(value);
    const sorted = [...filteredAllBookings].sort((a, b) => {
      if (value === 'name') {
        return a.name.localeCompare(b.name);
      } else if (value === 'tripStart') {
        const dateA = a.reschedule_date ? moment(a.reschedule_date) : moment(a.date);
        const dateB = b.reschedule_date ? moment(b.reschedule_date) : moment(b.date);
        return dateB.diff(dateA);
      } else if (value === 'totalPrice') {
        return a.totalPrice - b.totalPrice;
      } else {
        return 0;
      }
    });
    
    setFilteredAllBookings(sorted);
  };

  const handleUndo = () => {
    if (lastCancelledBooking) {
      // Restore the last cancelled booking
      const updatedCancelledBookings = cancelledBookings.filter(booking => booking.id !== lastCancelledBooking.id);
      setCancelledBookings(updatedCancelledBookings);

      const updatedBookings = [...bookings, lastCancelledBooking];
      setBookings(updatedBookings);
      setFilteredAllBookings(updatedBookings);

      // Clear the last cancelled booking
      setLastCancelledBooking(null);

      notification.success({
        message: 'Booking successfully restored',
      });
    } else {
      notification.error({
        message: 'No cancelled booking to restore',
      });
    }
  };

  return (
    <main className='ml-56'>
      <section className="mt-5">
        <h1>All Bookings</h1>
        <div className="flex gap-4 mb-4">
          <AutoComplete
            style={{ width: 200 }}
            value={searchText}
            onChange={handleSearch}
            placeholder="Search by name"
          />
          <Select
            style={{ width: 200 }}
            value={filterStatus}
            onChange={handleFilterChange}
            placeholder="Filter by status"
          >
            <Option value="All">All</Option>
            <Option value="paid">Paid</Option>
            <Option value="pending">Pending</Option>
            <Option value="cancelled">Cancelled</Option>
          </Select>
          <Select
            style={{ width: 200 }}
            value={sortKey}
            onChange={handleSortChange}
            placeholder="Sort by"
          >
            <Option value="name">Name</Option>
            <Option value="tripStart">Trip Start Date</Option>
            <Option value="totalPrice">Total Price</Option>
          </Select>
        </div>
        <div className="activities-list w-full flex gap-4 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">
          {filteredAllBookings.map((activity) => (
            <div key={activity.id} className="flex-1 min-w-[292px] max-w-[340px] w-[280px]">
              <div className="bg-white border-2 border-white rounded-2xl p-2 transition-all relative shadow-lg image-container">
                <div className='relative group'>
                  <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${activity.trip.mainphoto}`} alt="activity" />
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium leading-tight text-gray-700">{activity.trip.tripname}</h2>
                </div>
                <div className="mt-4 relative">
                  <p className="text-sm text-gray-800 w-full">
                    <span className="font-medium w-1/2">Customer name: </span>{activity.fullName}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Mail Id: </span>{activity.email}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Company: </span>{activity.trip.company}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Phone: </span>{activity.phone}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Trip date: </span>{activity.date}
                  </p>
                  {activity.reschedule_date && (
                    <p className="text-sm text-red-600">
                      <span className="font-medium">Rescheduled date: </span>{activity.reschedule_date}
                    </p>
                  )}
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Booking date & time: </span>{activity.createdAt}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment method: </span>{activity.paymentMethod}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Trip status: </span>{activity.tripStatus}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment status: </span>{activity.paymentStatus}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Total Price: </span>${activity.totalPrice}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Booking Code: </span>{activity.bookingCode}
                  </p>
                  <div className='flex gap-2 mt-3'>
                    <button onClick={() => showEditModal(activity)} className="bg-blue-500 w-full text-white px-3 py-1 rounded-md">Edit</button>
                    <button onClick={() => showModal(activity)} className="bg-red-500 text-white px-3 py-1 rounded-md">Cancel</button>
                    <button onClick={() => showRescheduleModal(activity)} className="bg-green-500 text-white px-3 py-1 rounded-md">Reschedule</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

      </section>
      <Modal
        title="Confirm Removal"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to cancel this booking?</p>
        <Input.TextArea
          rows={4}
          value={cancelReason}
          onChange={handleCancelReasonChange}
          placeholder="Please provide a reason for removal"
        />
      </Modal>
      <Modal
        title="Edit Booking"
        visible={editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Customer Name" name="fullName" rules={[{ required: true, message: 'Please enter customer name' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Mail ID" name="email" rules={[{ required: true, message: 'Please enter mail ID' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please enter phone number' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Payment Method" name="paymentMethod" rules={[{ required: true, message: 'Please enter payment method' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Trip Status"
            name="tripStatus"
            rules={[{ required: true, message: 'Please select a trip status' }]}
          >
            <Select
              placeholder="Select trip status"
              // Optionally set initial value if needed
              // defaultValue="pending"
              style={{ width: '100%' }}
            >
              <Option value="payment pending">payment pending</Option>
              <Option value="confirmed">confirmed</Option>
              <Option value="in progress">in progress</Option>
              <Option value="completed">completed</Option>
              <Option value="cancelled">cancelled</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Payment Status"
            name="paymentStatus"
            rules={[{ required: true, message: 'Please select a payment status' }]}
          >
            <Select
              placeholder="Select payment status"
              // Optionally set initial value if needed
              // defaultValue="pending"
              style={{ width: '100%' }}
            >
              <Option value="pending">Pending</Option>
              <Option value="paid">Paid</Option>
              <Option value="failed">Failed</Option>
              <Option value="refunded">Refunded</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Total Price" name="totalPrice" rules={[{ required: true, message: 'Please enter total price' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reschedule Booking"
        visible={rescheduleModalVisible}
        onOk={handleRescheduleModalOk}
        onCancel={handleRescheduleModalCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Trip Date" name="date" rules={[{ required: true, message: 'Please select trip start date' }]}>
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </Form>
      </Modal>
    </main>
  );
};

export default Bookings;
