import React, { useRef, useState, useEffect } from 'react';
import { Table, Modal, Button, Input, Space, message, Form } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

const UserManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const navigate = useNavigate();


  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, searchText, searchedColumn]);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/api/user/all', {
        params: {
          page: currentPage,
          limit: pageSize,
          searchColumn: searchedColumn,
          searchText: searchText
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data.users);
      setTotalUsers(response.data.total);
    } catch (error) {
      message.error('Failed to fetch users');
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate('/admin/login');
      } else {
        console.error('Error fetching trip data:', error);
      }
    }
  };


  const handleModalOpen = (user = null) => {
    setSelectedUser(user);
    if (user) {
      form.setFieldsValue(user);
      setIsEditing(true);
      setIsAdding(false);
    } else {
      form.resetFields();
      setIsAdding(true);
      setIsEditing(false);
    }
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
    setIsAdding(false);
  };


  const showRemoveConfirm = (userId) => {
    confirm({
      title: 'Are you sure you want to remove this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes, remove',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemoveUser(userId);
      },
    });
  };

  const handleRemoveUser = async (userId) => {
    try {
      await axiosInstance.delete(`/api/user/delete/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(users.filter(user => user._id !== userId));
      message.success('User removed successfully');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to remove user');
    }
  };

  const handleSaveChanges = async () => {
    try {
      const values = await form.validateFields();
      await axiosInstance.put(`/api/user/update/${selectedUser._id}`, values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const updatedUsers = users.map(user =>
        user._id === selectedUser._id ? { ...user, ...values } : user
      );
      setUsers(updatedUsers);
      message.success('User details updated successfully');
      setIsModalVisible(false);
      setIsEditing(false);
      await fetchUsers(); // Refresh the list
    } catch (error) {
      message.error('Failed to update user details');
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
    setCurrentPage(1);
  };

  const handleAddAUser = async () => {
    try {
      const values = await form.validateFields();
      const response = await axiosInstance.post('/api/user/create', values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      setUsers([...users, response.data.newUser]);
      message.success('User added successfully');
      setIsModalVisible(false);
      setIsAdding(false);
      await fetchUsers(); // Refresh the list
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 180 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: '30%',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleModalOpen(record)}
            style={{ background: '#1890ff', borderColor: '#1890ff' }}
          >
            Edit
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => showRemoveConfirm(record._id)}
            style={{
              background: 'white',
              borderColor: '#ff4d4f'
            }}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];


  return (
    <div className="user-management ml-52 pl-3 pr-3 pt-10">
      <div className="flex justify-between items-center">

        <h1 className="text-gray-800 font-medium text-xl">User Management</h1>
        <Button
          type="primary"
          onClick={() => handleModalOpen()}
        >
          Add User
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={users}
        rowKey="_id"
        className="mt-10"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalUsers,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }
        }}
        rowClassName={() => 'hover-row'}
        bordered
      />
      <Modal
        title={isAdding ? 'Add New User' : isEditing ? 'Edit User Details' : 'User Details'}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          isAdding && (
            <Button key="add" type="primary" onClick={handleAddAUser}>
              Create User
            </Button>
          ),
          isEditing && (
            <Button key="save" type="primary" onClick={handleSaveChanges}>
              Update User
            </Button>
          ),
          isEditing && (
            <Button
              key="remove"
              danger
              onClick={() => showRemoveConfirm(selectedUser._id)}
            >
              Delete User
            </Button>
          ),
          <Button key="back" onClick={handleModalCancel}>
            Cancel
          </Button>,
        ]}

      >
        <Form
          form={form}
          layout="vertical"
          initialValues={selectedUser || {}}
        >
          <Form.Item
            name="username"
            label="Name"
            rules={[{ required: true, message: 'Please input the user\'s name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{
              required: true,
              type: 'email',
              message: 'Please input a valid email!'
            }]}
          >
            <Input />
          </Form.Item>

          {!selectedUser && (
            <Form.Item
              name="password"
              label="Password"
              rules={[{
                required: true,
                message: 'Password is required for new users and must be at least 6 characters long!',
                min: 6,
                max: 20
              }]}
            >
              <Input.Password />
            </Form.Item>
          )}

          {selectedUser && (
            <>
              <h3>Booked Activities:</h3>
              <ul>
                {selectedUser.bookedActivities?.map((activity, index) => (
                  <li key={index}>
                    {activity.title} - {activity.date}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Form>
      </Modal>

    </div>
  );
};

export default UserManagement;