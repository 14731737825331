import React, { useEffect, useState } from 'react';
import AdminReview from '../../Components/AdminReview';
import { axiosInstance } from '../../API/authapi';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

function ReviewManagement() {
  const navigate = useNavigate();

  // Sample data (replace with actual data handling logic)
  const [newReviews, setNewReviews] = useState([]);

  const [allReviews, setAllReviews] = useState([
    {
      id: 1,
      profile: '../../assets/sample-dp-1.png',
      mail: 'john@gmail.com',
      phone: '123455',
      activity: 'underwater',
      name: 'Eleanor James',
      place: 'New york',
      star: 5,
      date: '22-05-2024',
      view: true,
      testimonial:
        'The Hato Caves are a must-visit! The guided tour was fascinating, and the caves are incredibly beautiful. The stalactites and stalagmites are stunning, and the historical stories add so much to the experience. Highly recommended!'
    },
    {
      id: 2,
      profile: '../../assets/sample-dp-1.png',
      mail: 'john@gmail.com',
      phone: '123455',
      activity: 'underwater',
      name: 'Eleanor James',
      place: 'New york',
      star: 5,
      date: '22-05-2024',
      view: false,
      testimonial:
        'The Hato Caves are a must-visit! The guided tour was fascinating, and the caves are incredibly beautiful. The stalactites and stalagmites are stunning, and the historical stories add so much to the experience. Highly recommended!'
    }
  ]);

  const [bookings, setBookings] = useState([]); 

  useEffect(() => {
    // Fetch reviews data from API
    axiosInstance.get('/api/rating/reviews')
      .then(response => {
        const reviews = response.data;
        
        // Set all reviews
        setAllReviews(reviews);

        // Filter reviews with status 'new'
        const filteredNewReviews = reviews.filter(review => review.status === 'new');
        setNewReviews(filteredNewReviews);
      })
      .catch(error => { 
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          notification.error({
            message: error.message,
          });
          navigate('/admin/login');
        } else {
          notification.error({
            message: error.message,
          });
          console.error('Error fetching trip data:', error);
        }

      });
  }, []);
  // Function to handle review deletion
  const handleDelete = (reviewId, section) => {
    if (section === 'new') {
      const updatedReviews = newReviews.filter(review => review.id !== reviewId);
      setNewReviews(updatedReviews);
    } else if (section === 'all') {
      const updatedReviews = allReviews.filter(review => review.id !== reviewId);
      setAllReviews(updatedReviews);
    }
  };

  // Function to handle publishing (toggle view state, for example)
  const handlePublish = (reviewId, section) => {
    if (section === 'new') {
      const updatedReviews = newReviews.map(review => {
        if (review.id === reviewId) {
          return { ...review, view: !review.view }; // Toggle the view state
        }
        return review;
      });
      setNewReviews(updatedReviews);
    } else if (section === 'all') {
      const updatedReviews = allReviews.map(review => {
        if (review.id === reviewId) {
          return { ...review, view: !review.view }; // Toggle the view state
        }
        return review;
      });
      setAllReviews(updatedReviews);
    }
  };

  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10'>
        <h1 className='text-gray-800 font-medium text-xl'>Review Management</h1>
        <div className='flex justify-between w-full items-center'>
          {/* Additional header content if needed */}
        </div>
      </header>
      <section>
        <h1 className='mt-2 text-gray-500'>New Reviews</h1>
        <div className='grid grid-cols-2 gap-4'>
          {newReviews.map(review => (
            <AdminReview
              key={review.id}
              newReview={review}
              onDelete={() => handleDelete(review._id, 'new')}
              onPublish={() => handlePublish(review._id, 'new')}
            />
          ))}
        </div>
      </section>
      <section>
        <h1 className='mt-2 text-gray-500'>All Reviews</h1>
        <div className='grid grid-cols-2 gap-4'>
          {allReviews.map(review => (
            <AdminReview
              key={review.id}
              newReview={review}
              onDelete={() => handleDelete(review._id, 'all')}
              onPublish={() => handlePublish(review._id, 'all')}
            />
          ))}
        </div>
      </section>
    </main>
  );
}

export default ReviewManagement;
