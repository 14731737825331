import React, { useEffect } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosInstance } from '../API/authapi';

const LogIn = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Use this to get the previous location
  const from = location.state?.from?.pathname || '/'; // Fallback to home if no previous page

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      message.warning('Already Logged In.');
      navigate('/'); // Redirect to home if the user is already logged in
    }
  }, [navigate]);

  const onFinish = async (values) => {
    try {
      const response = await axiosInstance.post('/api/user/login', values);
      localStorage.setItem('token', response.data.token); // Store token in local storage
      localStorage.setItem('username', response.data.username); // Store username in local storage
      localStorage.setItem('email', response.data.email); // Store username in local storage
      message.success('Logged in successfully');
      navigate(from); // Redirect to the previous page or home if no previous page
    } catch (error) {
      message.error(error.response.data.message || 'Login failed');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <a className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
      <Card title="Login" className="w-full max-w-md text-center mt-5">
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Log In
            </Button>
          </Form.Item>
        </Form>

        <p className="text-center">
          Don't have an account?{' '}
          <a href='/signup' className="text-theorange-400 cursor-pointer">
            Sign Up
          </a>
        </p>
        <a href="/forgotpassword" className="text-theorange-400 cursor-pointer">
          Forgot Password?
        </a>
      </Card>
    </div>
  );
};

export default LogIn;