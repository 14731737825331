import React, { useEffect, useState } from 'react';
import { Card, Spin, Tag, message } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { axiosInstance } from '../API/authapi';
import SecondaryHeader from '../Components/SecondaryHeader';

function BookingConfirmed() {
  const [loading, setLoading] = useState(true);
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    const orderId = new URLSearchParams(window.location.search).get('orderId');
    const bookingId = new URLSearchParams(window.location.search).get('bookingId');
    const image = new URLSearchParams(window.location.search).get('tripimage');
    const tripname = new URLSearchParams(window.location.search).get('tripname');


    if (orderId) {
      axiosInstance
        .post('/api/checkout/payment-callback', { orderId, id: bookingId })
        .then((response) => {
          message.success('Payment status updated successfully');
          setBookingData({ ...response.data.booking, tripImage: image ,tripname:tripname });
        })
        .catch((error) => {
          message.error(error.response?.data?.message || 'Failed to update payment status');
          setBookingData({ paymentStatus: 'Error', tripStatus: 'Error', tripImage: image,tripname:tripname });
        })
        .finally(() => setLoading(false));
    }
  }, []);

  const getPaymentColor = (status) => {
    switch (status.toLowerCase()) {
      case 'paid': return 'green';
      case 'failed': return 'red';
      default: return 'orange';
    }
  };
  const options = { year: "numeric", month: "long", day: "numeric" };


  const getTripColor = (status) => {
    switch (status.toLowerCase()) {
      case 'confirmed': return 'green';
      case 'payment pending': return 'orange';
      default: return 'volcano';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Spin tip="Please don't close this page. Processing..." size="large" />
      </div>
    );
  }

  if (!bookingData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-lg text-red-500">Failed to load booking details.</p>
      </div>
    );
  }

  return (
    <div>
      <SecondaryHeader />

    <div className="booking-confirmed-container flex flex-col justify-center items-center">
      <Card className="w-11/12 md:w-2/3 p-5 rounded-xl shadow-lg">
        <div className="text-center mb-5">
          <h2 className="text-xl font-semibold text-gray-800">Booking Details</h2>
          <p className="text-gray-500">Thank you for booking with us!</p>
        </div>

        <div className="flex flex-col md:flex-row gap-5">
          <div className="w-full md:w-1/2">
            <img
              src={`https://tripcurascobackend-production.up.railway.app/${bookingData.tripImage}`}
              alt="Trip Image"
              className="rounded-lg w-full h-48 object-cover"
            />
          </div>

          <div className="flex-1 text-gray-700">
            <p><strong>Trip Name:</strong> {bookingData.tripname}</p>
            <p><strong>Booking Code:</strong> {bookingData.bookingCode}</p>
            <p><strong>Date:</strong> <CalendarOutlined className="text-blue-500" /> {new Date(bookingData.date).toLocaleDateString(undefined, options)}</p>
            <p><strong>Guests:</strong> {bookingData.adult + bookingData.child}</p>
            <p><strong>Total Cost:</strong> ${bookingData.totalPrice}</p>

            <p className="mt-3">
              <strong>Payment Status:</strong> {' '}
              <Tag color={getPaymentColor(bookingData.paymentStatus)} style={{ fontWeight: 'bold' }}>
                {bookingData.paymentStatus.toUpperCase()}
              </Tag>
            </p>
            <p>
              <strong>Trip Status:</strong> {' '}
              <Tag color={getTripColor(bookingData.tripStatus)} style={{ fontWeight: 'bold' }}>
                {bookingData.tripStatus.toUpperCase()}
              </Tag>
            </p>
          </div>
        </div>
      </Card>
    </div>
    </div>
  );
}

export default BookingConfirmed;
