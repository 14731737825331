import React, { useEffect, useState } from 'react';
import { ColorPicker, Upload, Input, Button, Select, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../API/authapi';
import { useNavigate } from 'react-router-dom';

function EditContent() {
  const [color, setColor] = useState('#1677ff');
  const [heroTitle, setHeroTitle] = useState('Plan your Curacao trip today');
  const [heroImage, setHeroImage] = useState('../../assets/Hero-image.jpg');
  const [fileList, setFileList] = useState([]);
  const [selectedSpecialDeals, setSelectedSpecialDeals] = useState([]);
  const [selectedTrendingNow, setSelectedTrendingNow] = useState([]);
  const [selectedExperienceCuracao, setSelectedExperienceCuracao] = useState([]);
  const [selectedBeaches, setSelectedBeaches] = useState([]);
  const [selectedThingsToDo, setSelectedThingsToDo] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [chamberOfCommerceNumber, setChamberOfCommerceNumber] = useState('89766520');
  const [legalName, setLegalName] = useState('Magnet Group');
  const [emailAddress, setEmailAddress] = useState('info@thetripcuracao.com');
  const [contact, setContact] = useState('+31687205513');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state



  useEffect(() => {
    axiosInstance.get('/api/home/admin') 
      .then(response => {
        const data = response.data[0];
        setHeroTitle(data.title || '');
        setColor(data.color || '#1677ff');
        setChamberOfCommerceNumber(data.chamber_of_commerce_number || '');
        setLegalName(data.legal_name || '');
        setEmailAddress(data.email_address || '');
        setContact(data.contact || '');
        setSelectedSpecialDeals(data.special_deals || []);
        setSelectedTrendingNow(data.trending_now || []);
        setSelectedExperienceCuracao(data.experience_curacao || []);
        setSelectedBeaches(data.beaches || []);
        setSelectedThingsToDo(data.things_to_do || []);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate('/admin/login');
        } else {
          console.error('Error fetching trip data:', error);
        }
      });

    axiosInstance.get('/api/trips/admin')
      .then(response => {
        setBookings(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate('/admin/login');
        } else {
          console.error('Error fetching trip data:', error);
        }
      });
  }, []);

  const tripsoptions = bookings.map((booking) => ({
    label: booking.tripname,
    value: booking._id,
  }));

  const handleImageChange = (info) => {
    if (info.file.status === 'done') {
      const newImage = URL.createObjectURL(info.file.originFileObj);
      setHeroImage(newImage);
    }
    setFileList(info.fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', heroTitle);
    formData.append('color', color);
    if (fileList.length > 0) {
      formData.append('photos', fileList[0].originFileObj);
    }
    formData.append('special_deals', JSON.stringify(selectedSpecialDeals));
    formData.append('trending_now', JSON.stringify(selectedTrendingNow));
    formData.append('experience_curacao', JSON.stringify(selectedExperienceCuracao));
    formData.append('beaches', JSON.stringify(selectedBeaches));
    formData.append('things_to_do', JSON.stringify(selectedThingsToDo));
    formData.append('chamber_of_commerce_number', chamberOfCommerceNumber);
    formData.append('legal_name', legalName);
    formData.append('email_address', emailAddress);
    formData.append('contact', contact);

    try {
      const response = await axiosInstance.post('/api/home', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Home content updated successfully!');
      console.log(response.data);
    } catch (error) {
      console.error(error);
      message.error(error.response?.data?.error || 'Something went wrong');
    }
  };


  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10'>
        <h1 className='text-gray-800 font-medium text-xl'>Edit</h1>
      </header>
      <section className=' mt-10'>
        <div className='flex'>
          <div className='w-1/2 flex gap-8 justify-between'>
            <p className=' text-nowrap'>Hero Image</p>
            <div className='relative'>
              <img
                src={heroImage}
                alt="Hero"
                className='w-7/12 rounded-lg'
              />
              <Upload
                name="heroImage"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok");
                  }, 0);
                }}
                onChange={handleImageChange}
                className='absolute bottom-2 left-2 w-full'
              >
                <Button icon={<UploadOutlined />}>Change Image</Button>
              </Upload>
            </div>
          </div>
          <div className='w-1/2 flex flex-col gap-8'>
            <div className='flex justify-start items-center gap-4'>
              <p>Hero Title</p>
              <Input
                value={heroTitle}
                onChange={(e) => setHeroTitle(e.target.value)}
                className='w-2/3'
              />
            </div>
            <div className='flex justify-start gap-4 items-center'>
              <p>Header Nav Color</p>
              <ColorPicker value={color} onChange={setColor} />
            </div>
          </div>
        </div>
        <h1 className='text-gray-800 font-medium text-xl mt-10'>Edit sections</h1>
        <div className='flex justify-between mt-4'>
          <p>Special deals:</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '70%' }}
            placeholder="Please select related trip"
            options={tripsoptions}
            value={selectedSpecialDeals}
            onChange={setSelectedSpecialDeals}
          />
        </div>

        <div className='flex justify-between mt-4'>
          <p>Trending now:</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '70%' }}
            placeholder="Please select related trip"
            options={tripsoptions}
            value={selectedTrendingNow}
            onChange={setSelectedTrendingNow}
          />
        </div>

        <div className='flex justify-between mt-4'>
          <p>Experience Curacao like a local:</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '70%' }}
            placeholder="Please select related trip"
            options={tripsoptions}
            value={selectedExperienceCuracao}
            onChange={setSelectedExperienceCuracao}
          />
        </div>

        <div className='flex justify-between mt-4'>
          <p>Beaches:</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '70%' }}
            placeholder="Please select related trip"
            options={tripsoptions}
            value={selectedBeaches}
            onChange={setSelectedBeaches}
          />
        </div>

        <div className='flex justify-between mt-4'>
          <p>Things to do:</p>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '70%' }}
            placeholder="Please select related trip"
            options={tripsoptions}
            value={selectedThingsToDo}
            onChange={setSelectedThingsToDo}
          />
        </div>
      </section>
      <section className=' mb-8'>
        <h1 className='text-gray-800 font-medium text-xl mt-10'>Edit footer details</h1>
        <div className=' flex justify-between mt-5'>
          <p>Chamber of commerce number:</p>
          <Input
            style={{ width: '70%' }}
            value={chamberOfCommerceNumber}
            onChange={(e) => setChamberOfCommerceNumber(e.target.value)}
          />
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Legal name:</p>
          <Input
            style={{ width: '70%' }}
            value={legalName}
            onChange={(e) => setLegalName(e.target.value)}
          />
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Email address:</p>
          <Input
            style={{ width: '70%' }}
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Contact:</p>
          <Input
            style={{ width: '70%' }}
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
        </div>
        <Button onClick={handleSubmit} className='bg-blue-500 text-white mt-8'>Save Changes</Button>
      </section>
    </main>
  );
}

export default EditContent;
