import React, { createContext, useState, useContext, useCallback } from 'react';

const LoadingContext = createContext();

let globalUpdateLoader = () => {};

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateLoader = useCallback((show) => {
    setIsLoading(show);
  }, []);

  const setTimedLoader = useCallback((duration) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), duration);
  }, []);

  // Set the global updateLoader function
  globalUpdateLoader = updateLoader;

  return (
    <LoadingContext.Provider value={{ isLoading, updateLoader, setTimedLoader }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

// Export the global updateLoader function
export const getGlobalUpdateLoader = () => globalUpdateLoader;