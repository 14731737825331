import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from 'axios';


function CurrencyChange() {

  const [selectedCurrency,setSelectedCurrency] = useState([])
  
  const getUsdToEurCurrecyRates = async() => {
    try {
      const data = await axios.get(
        'https://latest.currency-api.pages.dev/v1/currencies/usd.json'
      );
      console.log(data.data.usd.eur);
      localStorage.setItem('usdToEur',data.data.usd.eur)
      } catch (error) {
        console.log('err',error);
        
      }
    }

    const getEurToUsdCurrecyRates = async() => {
      try {
        const data = await axios.get(
          'https://latest.currency-api.pages.dev/v1/currencies/eur.json'
        );
        console.log(data.data.eur.usd);
        localStorage.setItem('eurToUsd',data.data.eur.usd)
        } catch (error) {
          console.log('err',error);
          
        }
      }
      
    useEffect(() => {
       getUsdToEurCurrecyRates();
       getEurToUsdCurrecyRates()
    },[])

  const defaultValue = () => {
    if(localStorage.getItem('selectedCurrency')?.length){
      return localStorage.getItem('selectedCurrency')
    }else{
      return 'USD'
    }
  }  

  const handleChange = (value) => {
    if(value === localStorage.getItem('currentFormat')){
      return;
    }
    localStorage.setItem('selectedCurrency',value)
    setSelectedCurrency([...selectedCurrency])
    window.location.reload();
  };

  return (
    <div className=''>
      <Select
        showSearch
        placeholder="Currency"
        optionFilterProp="label"
        defaultValue={defaultValue}
        onChange={handleChange}
        // onSearch={onSearch}
        options={[
          {
            value: 'USD',
            label: 'USD',
          },
          {
            value: 'EUR',
            label: 'EUR',
          },
        ]}
      />
      {/* <Select
      className=' fixed'
    //   placeholder='Currency'
      defaultValue="USD"
      style={{
        width: 120,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'USD',
          label: 'USD',
        },
        {
          value: 'AED',
          label: 'AED',
        },
        {
          value: 'INR',
          label: 'INR',
        },
        {
          value: 'GBP',
          label: 'GBP',
        },
      ]}
    /> */}
    </div>
  )
}

export default CurrencyChange
