import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { DatePicker, } from 'antd';
import { Button, Dropdown } from 'antd';
import MainFooter from '../Components/MainFooter';
import SecondaryHeader from '../Components/SecondaryHeader';
import { Modal, message } from 'antd';
import { CalendarFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { EnvironmentOutlined } from '@ant-design/icons';
import { axiosInstance } from '../API/authapi';
// import CancellationPolicy from '../Components/CancellationPolicy';

const handleChange = (value) => {
  console.log(`Selected: ${value}`);
};
const { confirm } = Modal;

const { RangePicker } = DatePicker;

// const options = [
//   { value: 'en', label: 'English' },
//   { value: 'es', label: 'Español' },
//   { value: 'de', label: 'Deutsch' },
// ];


// console.log(options);


const formatDate = (dateString) => {
  // Check if dateString is valid
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  return date.toISOString().split('T')[0];
}

const MyAccount = () => {
  const navigate = useNavigate();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState('Jhon Doe');
  const [loggedIn, setLoggedIn] = useState(true)
  const [userMail, setUserMail] = useState('johndoe@gmail.com')
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOpenModal = (activity, action) => {
    setSelectedActivity(activity); // Save the selected activity in state

    // Open the appropriate modal based on the action
    if (action === 'cancel') {
      setIsCancellationModalOpen(true);
    } else if (action === 'refund') {
      setIsRefundModalOpen(true);
    }
  };

  // Items for dropdown
  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => handleOpenModal(selectedActivity, 'cancel')}>
          Cancellation Policy
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={() => handleOpenModal(selectedActivity, 'refund')}>
          Reschedule Policy
        </a>
      ),
    },
  ];
  const handleManageClick = (id) => {
    setDropdownVisible(dropdownVisible === id ? null : id);
  };

  const handleOptionClick = (option, activity) => {
    console.log(option, activity);
    setDropdownVisible(null);
    // Implement the logic for each option
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [youmayalsolike, setyoumayalsolike] = useState([]);
  const [myBookings, setmyBookings] = useState([]);

  useEffect(() => {
    setUserName(localStorage.getItem('username'))
    setUserMail(localStorage.getItem('email'))
  }, [])

  useEffect(() => {
    // Fetch bookings data from API 
    axiosInstance.get(`/api/checkout/user/bookings`)
      .then(async response => {
        setmyBookings(response.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Clear local storage and redirect to home if unauthorized
          localStorage.clear();
          navigate('/login');
        } else {
          console.error('Error fetching bookings data:', error);
        }
      });

    // Fetch favorite trips
    axiosInstance.get(`/api/favorite/favorite-trips`)
      .then(async response => {
        setyoumayalsolike(response.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Clear local storage and redirect to home if unauthorized
          localStorage.clear();
          navigate('/login');
        } else {
          console.error('Error fetching favorite trips:', error);
        }
      });
  }, [navigate]);

  const [size, setSize] = useState('middle');

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getPaymentClass = (status) => {
    switch (status) {
      case "paid":
        return "bg-blue-100 text-blue-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "failed":
        return "bg-red-100 text-red-800";
      case "refunded":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getTripStatusClass = (status) => {
    switch (status) {
      case "payment pending":
        return "bg-yellow-100 text-yellow-800";
      case "confirmed":
        return "bg-blue-100 text-blue-800";
      case "in progress":
        return "bg-purple-100 text-purple-800";
      case "completed":
        return "bg-green-100 text-green-800";
      case "cancelled":
        return "bg-red-100 text-red-800";
      case "Refunded":
        return "bg-green-100 text-green-800";
      case "Refund Rejected":
        return "bg-red-100 text-red-800";
      case "Reschedule Rejected":
        return "bg-orange-100 text-orange-800";
      case "Requested Reschedule":
        return "bg-indigo-100 text-indigo-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };


  const showLogoutConfirm = () => {
    confirm({
      title: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      content: 'Logging out will end your current session.',
      okText: 'Yes, Log Out',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        setLoggedIn(false);
        message.success('You have been logged out successfully.');
      },
      onCancel() {
        message.info('Logout cancelled.');
      },
    });
  };

  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);


  const convertAmount = (amount) => {

    if (localStorage.getItem('currentFormat') === 'USD') {

      localStorage.setItem('currentFormat', 'USD')

      return `$ ${(amount * localStorage.getItem('eurToUsd')).toFixed(2)}`

    } else {

      localStorage.setItem('currentFormat', 'EUR')

      return `€ ${(amount * localStorage.getItem('usdToEur')).toFixed(2)}`

    }

  }

  const handleAction = async (record, actionType) => {
    const title = actionType === 'cancel'
      ? 'Are you sure you want to cancel this booking?'
      : actionType === 'refund'
        ? 'Are you sure you want to request a refund?'
        : 'Are you sure you want to reschedule this booking?';

    Modal.confirm({
      title,
      content: actionType === 'reschedule' && !selectedDate
        ? 'Please select a date before rescheduling.'
        : undefined,
      onOk: async () => {
        try {
          if (actionType === 'reschedule' && !selectedDate) {
            message.error('Please select a date first');
            return;
          }

          const updatedBooking = {
            tripStatus: actionType === 'cancel'
              ? 'Requested Cancellation'
              : actionType === 'refund'
                ? 'Requested Refund'
                : 'Requested Reschedule',
            ...(actionType === 'reschedule' && { reschedule_date: selectedDate.format('YYYY-MM-DD') })
          };

          const response = await axiosInstance.put(
            `/api/checkout/bookingscancel/${record._id}`,
            updatedBooking
          );

          if (response.status === 200) {
            message.success(
              actionType === 'cancel'
                ? 'Booking cancelled successfully'
                : actionType === 'refund'
                  ? 'Refund requested successfully'
                  : 'Reschedule requested successfully'
            );

            // Refresh the bookings data
            const bookingsResponse = await axiosInstance.get('/api/checkout/user/bookings');
            setmyBookings(bookingsResponse.data);

            // Close the modals
            setIsCancellationModalOpen(false);
            setIsRefundModalOpen(false);
          }
        } catch (error) {
          console.error(`Error processing ${actionType} request:`, error);
          message.error(`Failed to process ${actionType} request. Please try again.`);
        }
      },
      onCancel() {
        console.log('Action cancelled');
      },
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const LikeButton = ({ id }) => {
    const [isFilled, setIsFilled] = useState(true);
    console.log(id);

    const handleClick = async (e) => {

      e.preventDefault();

      try {
        if (isFilled) {
          // Remove favorite trip 
          await axiosInstance.delete("/api/favorite/remove-favorite-trip", { data: { tripId: id } });
        } else {
          // Add favorite trip
          await axiosInstance.post("/api/favorite/add-favorite-trip", { tripId: id });
        }

        // Toggle the filled state after successful request
        setIsFilled(!isFilled);
      } catch (error) {
        console.error("An error occurred:", error);
        // You might want to show an error message to the user
      }
    };

    return (
      <div
        className='bg-white w-8 h-8 rounded-full absolute top-1 right-1 flex justify-center items-center z-10'
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50012 12.2822L1.64353 6.97733C-1.53939 3.7944 3.13951 -2.31681 7.50012 2.62733C11.8607 -2.31681 16.5184 3.81562 13.3567 6.97733L7.50012 12.2822Z"
            stroke="#F0B059"
            strokeWidth="1.06098"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isFilled ? "orange" : "none"}
          />
        </svg>
      </div>
    );
  };
  return (

    <main>
      <SecondaryHeader />

      <section className='p-4 md:p-8'>
        <div className=' flex justify-between  items-center'>
          <div className=' flex justify-start gap-4 items-center'>
            <div className='w-20 h-20 bg-white rounded-full'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M12 3.75a3.75 3.75 0 1 0 0 7.5a3.75 3.75 0 0 0 0-7.5Zm-4 9.5A3.75 3.75 0 0 0 4.25 17v1.188c0 .754.546 1.396 1.29 1.517c4.278.699 8.642.699 12.92 0a1.537 1.537 0 0 0 1.29-1.517V17A3.75 3.75 0 0 0 16 13.25h-.34c-.185 0-.369.03-.544.086l-.866.283a7.251 7.251 0 0 1-4.5 0l-.866-.283a1.752 1.752 0 0 0-.543-.086H8Z" className=' fill-slate-200' /></svg>
            </div>
            <div>
              <p className=' font-bold text-xl md:text-3xl text-gray-700'>{loggedIn ? userName : 'Guest'}</p>
              <p className=' font-normal text-gray-500'>{loggedIn ? userMail : ''}</p>
            </div>
          </div>
          {loggedIn ? (
            <a
              onClick={showLogoutConfirm}
              className='text-theorange-400 opacity-70 hover:opacity-100 transition-all cursor-pointer'
            >
              Log Out
            </a>
          ) : (
            <a
              href='/userlogin'
              className='text-theorange-400 opacity-70 hover:opacity-100 transition-all cursor-pointer'
            >
              Sign Up or Log In
            </a>
          )}
          {/* <a href= '/userlogin' className=' text-theorange-400 opacity-70 hover:opacity-100 transition-all cursor-pointer'>{loggedIn? 'Log Out' : 'Sign Up or Log In' }</a> */}
        </div>
      </section>
      {/* <section className="p-5 md:p-8 ">
         <div className=' flex bg-white rounded-md px-4 py-4 justify-between'> 
          <p><EnvironmentOutlined /> Playa PortoMari, Curacao</p>
          <a href="" className=' opacity-50 hover:opacity-100 transition-all text-sm'>Change location</a>
         </div>
      </section> */}

      <section className="p-5 md:p-8 ">
        <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Favourites</h1>

        <div className="activities-list w-full flex  gap-4 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">

          {youmayalsolike.map(activity => (
            <Link to={`/trip/${activity._id}`} key={activity._id} className="flex-1 min-w-[292px] lg:max-w-[292px] w-[280px]">
              <div className="bg-white border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-lg image-container" style={{ minHeight: '380px' }} >
                <div className='relative group'>



                  <img className='w-full h-44 object-cover rounded-xl' src={`https://tripcurascobackend-production.up.railway.app/${activity.mainphoto}`} />
                  <LikeButton id={activity._id} />

                </div>
                {/* <ImageTrip /> */}
                <div className='flex justify-between mt-2'>
                  <h2 className=" text-md font-medium w-3/4 leading-tight m text-gray-700">{activity.tripname}</h2>

                  <div className='flex items-center gap-2'>
                    <p className=' text-gray-500'>4.0</p>
                    <img src="../../assets/svg/star.svg" alt="" />
                  </div>

                </div>

                <p className="text-theorange-400  font-normal text-xs">{activity.location}</p>


                <div className=' flex justify-between absolute bottom-5 w-11/12'>
                  <div className=' flex flex-col justify-center gap-1'>

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                      <img src="../../assets/svg/3days.svg" alt="" />
                      3-days
                    </div>

                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />

                      Best price
                    </div>

                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />

                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    {/* <button className=' p-1 pl-3 pr-3 bg-theblue-400 text-slate-50 font-normal rounded-md text-xs border-none'>Compare</button> */}
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>{convertAmount(activity.price)}</p>
                  </div>
                </div>

              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="p-5 md:p-8 ">
        <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">My Bookings</h1>

        <div className="activities-list w-full flex gap-6 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">
          {myBookings.map((activity) => (
            <div key={activity._id} className="flex-1 min-w-[300px] max-w-[340px] w-[300px]">
              <div className="bg-white border border-gray-200 rounded-2xl p-4 transition-all relative shadow-md hover:shadow-lg">
                <div className="relative group">
                  {activity.trip && activity.trip.mainphoto ? (
                    <img
                      className="w-full h-44 object-cover rounded-xl"
                      src={`https://tripcurascobackend-production.up.railway.app/${activity.trip.mainphoto}`}
                      alt="activity"
                    />
                  ) : (
                    <div className="w-full h-44 bg-gray-200 flex items-center justify-center rounded-xl">
                      No Image Available
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <h2 className="text-lg font-semibold leading-tight text-gray-800 truncate">
                    {activity.trip ? activity.trip.tripname : 'Trip Name Not Available'}
                  </h2>
                  <p className="text-sm text-gray-600">{activity.trip ? `by ${activity.trip.company}` : ''}</p>
                </div>

                <div className="mt-4">
                  <p className="text-sm font-semibold text-gray-800 bg-blue-50 rounded-lg p-2 shadow-inner">
                    Booking Code: <span className="text-blue-600">{activity.bookingCode || 'Code Not Available'}</span>
                  </p>
                  <p className="flex items-center gap-2 mt-3 text-sm text-gray-800">
                    <CalendarFilled className="w-4 h-4 text-blue-600" />
                    <span className="font-semibold">Trip Date: </span>
                    <span className="text-blue-600">{activity.date ? formatDate(activity.date) : 'Date Not Available'}</span>
                  </p>
                  <p className="mt-2">
                    <span className="font-semibold">Trip Status: </span>
                    <span className={`px-2 py-1 rounded ${getTripStatusClass(activity.tripStatus)}`}>
                      {activity.tripStatus || 'Trip Status Not Available'}
                    </span>
                  </p>
                  <p className="mt-1">
                    <span className="font-semibold">Payment Status: </span>
                    <span className={`px-2 py-1 rounded ${getPaymentClass(activity.paymentStatus)}`}>
                      {activity.paymentStatus || 'Payment Status Not Available'}
                    </span>
                  </p>
                  <p className="text-sm text-gray-800 mt-2">
                    <span className="font-medium">Customer Name: </span>{activity.fullName || 'Name Not Available'}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Mail ID: </span>{activity.email || 'Email Not Available'}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Phone: </span>{activity.phone || 'Phone Not Available'}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment Method: </span>{activity.paymentMethod || 'Payment Method Not Available'}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Total Price: </span>${activity.totalPrice || '0'}
                  </p>

                  {activity.reschedule_date && (
                    <p className="text-sm text-red-600">
                      <span className="font-medium">Rescheduled Date: </span>{activity.reschedule_date ? formatDate(activity.reschedule_date) : ''}
                    </p>
                  )}

                  <p className="text-sm text-gray-800 mt-2">
                    <span className="font-medium">Booking Date: </span>
                    {activity.createdAt ? formatDate(activity.createdAt) : 'Booking Date Not Available'}
                  </p>

                  <div className="flex gap-3 mt-4">
                    <Dropdown
                      menu={{ items }}
                      trigger={['click']}
                      overlayClassName="custom-dropdown"
                      className="w-full"
                    >
                      <Button
                        className="bg-blue-500 text-white w-full px-4 py-2 rounded-md hover:bg-blue-600"
                        onClick={() => setSelectedActivity(activity)}
                      >
                        Manage
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>


      </section>

      <MainFooter />

      <Modal
        title="Cancellation Policy"
        visible={isCancellationModalOpen}
        onCancel={() => setIsCancellationModalOpen(false)}
        footer={null}
      >
        <p>
          <strong>Free Cancellation:</strong> You can cancel your experience up to 24 hours in advance for a full refund.
          <br />
          <strong>Late Cancellations:</strong> If you cancel less than 24 hours before the start of the experience, no refund will be issued.
          <br />
          <strong>No Show Policy:</strong> Failure to show up for your scheduled experience will result in no refund. <strong>NO SHOW = NO REFUND!</strong>
          <br />
          <strong>Tour Operator Discretion:</strong> We will do our best to assist and mitigate any disputes regarding cancellations. However, please note that the final decision rests with the tour operator.
          <br />
          These regulations apply to all tour bookings facilitated through TheTripCuraçao.
          <br />
          For any cancellation inquiries, please contact us at <a href="mailto:info@thetripcuracao.com">info@thetripcuracao.com</a>.
        </p>

        {selectedActivity && (
          <div className='w-full flex justify-start gap-2'>
            {selectedActivity?.tripStatus === 'Requested Cancellation' ? (
              <p className="text-blue-500 ">Cancellation is already requested. Please wait for approval.</p>
            ) : (
              <Button
                type="primary"
                onClick={() => handleAction(selectedActivity, 'cancel')}
              >
                Cancel trip
              </Button>
            )}
            {selectedActivity?.tripStatus === 'cancelled' && (
              <Button onClick={() => handleAction(selectedActivity, 'refund')}>Ask for refund</Button>
            )}
          </div>
        )}
      </Modal>

      <Modal
        title="Reschedule Policy"
        visible={isRefundModalOpen}
        onCancel={() => setIsRefundModalOpen(false)}
        footer={null}
      >
        <p>
          At TheTripCuraçao, we aim to provide you with the best travel experience possible. Here’s how our refund process works:
          <br />
          <strong>Eligibility for Refunds:</strong> Refunds are only available for cancellations made at least 24 hours before the scheduled start time of the experience.
          <br />
          <strong>Refund Method:</strong> Refunds will be processed using the same payment method used at the time of booking. In certain cases, a refund may be issued in the form of a voucher at our discretion.
          <br />
          <strong>Processing Time:</strong> Refunds will be processed within 7-10 business days after the cancellation request has been confirmed.
          <br />
          <strong>No Show and Late Cancellation:</strong> No refunds will be provided for cancellations made less than 24 hours before the experience start time or for no-shows.
          <br />
          <strong>Weather-Related Refunds:</strong> If an experience is canceled due to inclement weather, you will be given the option to either reschedule for another date or receive a full refund.
          <br />
          <strong>Third-Party Services:</strong> For bookings involving third-party service providers, TheTripCuraçao will assist in facilitating the refund process, but the final decision rests with the service provider. Please understand that refunds can only be issued if the third-party service provider provides us with the refund amount.
          <br />
          For any refund inquiries, please contact us at <a href="mailto:info@thetripcuracao.com">info@thetripcuracao.com</a>.
        </p>


        <div className="mb-4">
          <strong>Select Reschedule Date:</strong>
          <DatePicker
            style={{ width: '100%', marginTop: '10px' }}
            onChange={handleDateChange}
            value={selectedDate}
            disabledDate={(current) => {
              // Disable dates before today
              return current && current.valueOf() < Date.now();
            }}
          />
        </div>

        <div className="w-full flex justify-end gap-2">
          <Button
            type="primary"
            onClick={() => handleAction(selectedActivity, 'reschedule')}
            disabled={
              selectedActivity?.reschedule_date ||
              (new Date(selectedActivity?.date) < new Date(Date.now() + 42 * 60 * 60 * 1000)) ||
              !selectedDate
            }
          >
            Reschedule
          </Button>
          {selectedActivity?.reschedule_date && (
            <p className="text-red-500">This activity has already been rescheduled.</p>
          )}
          {new Date(selectedActivity?.date) < new Date(Date.now() + 42 * 60 * 60 * 1000) && (
            <p className="text-red-500">
              You can only reschedule activities more than 42 hours before the start date.
            </p>
          )}
        </div>

      </Modal>

    </main>
  );
}

export default MyAccount;
// className='w-3/12 pl-4 pr-4 flex flex-col items-center gap-4'
