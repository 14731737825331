import React, { useEffect } from 'react';
import { Card, Button, Result, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { axiosInstance } from '../API/authapi';

// Helper to get query params from the URL
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function BookingConfirmed() {
  const query = useQuery();
  
  // Extract data from the query parameters
  const tripname = query.get('tripname') || 'Unknown Trip';
  const date = query.get('date') || 'Unknown Date';
  const guests = query.get('guests') || 0;
  const totalCost = query.get('totalCost') || '$0';
  const image = query.get('tripimage') || '$0';
  const  bookingId=query.get('bookingId')|| 'Bk';
  const orderId=query.get('orderId')||'T1';

  useEffect(() => {
    if (orderId) {
      axiosInstance
        .post('/api/checkout/payment-callback', { orderId,id:bookingId })
        .then(response => {
          message.success(response.data.message || 'Payment status updated successfully');
        })
        .catch(error => {
          message.error(error.response?.data?.message || 'Failed to update payment status');
        });
    }
  }, [orderId]); 

  return (
    <div className="booking-confirmed-container flex flex-col justify-between items-center">
      <Result
        status="success"
        title="Booking successful"
        subTitle="Thank you for doing business with us"
        extra={[ 
          <Button type="primary" key="console" href='/'>
            Back to home
          </Button>,
        ]}
      />

      <div className="bg-white p-3 md:p-5 w-2/3 rounded-xl shadow-sm" bordered={true}>
        <div className='flex flex-col md:flex-row gap-4'>
          <div className='md:w-1/2'>
            <img src={`https://tripcurascobackend-production.up.railway.app/${image}`} alt="Trip Image" className='rounded-xl' />
          </div>
          <div className='text-slate-800'>
          <p><strong>Trip Name:</strong> {tripname}</p>
          <p><strong>Booking Id:</strong> {bookingId}</p>
          <p><strong>Date:</strong> {date}</p>
            <p><strong>Guests:</strong> {guests}</p>
            <p><strong>Total Cost:</strong> ${totalCost}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmed;
