import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const CurrencyContext = createContext();

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState('BRL');
  const [exchangeRates, setExchangeRates] = useState({});


  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setExchangeRates(response.data.rates);
        console.log('Exchange rates updated:', response.data.rates);
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
      }
    };

    fetchExchangeRates();
    const interval = setInterval(fetchExchangeRates, 3600000);
    return () => clearInterval(interval);
  }, []);

  const convertPrice = (priceUSD) => {
    console.log('Converting price, current currency:', currency);
    if (currency === 'USD') return priceUSD.toFixed(2);
    if (!exchangeRates[currency]) return priceUSD;
    return (priceUSD * exchangeRates[currency]).toFixed(2);
  };


  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertPrice }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const CurrencyOptions = [
  { value: 'ANG', label: 'ANG' },
  { value: 'BRL', label: 'BRL' },
  { value: 'GBP', label: 'GBP' },
  { value: 'CAD', label: 'CAD' },
  { value: 'COP', label: 'COP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
  { value: 'VES', label: 'VES' },
];